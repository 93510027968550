interface carousalElement {
    heading: string,
    url: string,
    subHeading: string
}

export const carousaldata: carousalElement[] = [{
    heading: '50% Off on Every Product',
    url: 'https://opencart.opencartworks.com/themes/so_claue/layout5/image/cache/catalog/slideshow/home4/slide3-1920x600.jpg',
    subHeading: ''

}, {
    heading: 'Buy it what you want',
    url: 'https://opencart.opencartworks.com/themes/so_claue/layout5/image/cache/catalog/slideshow/home4/slide1-1920x600.jpg',
    subHeading: ''
},
{
    heading: '50% Off on Every Product',
    url: 'https://opencart.opencartworks.com/themes/so_claue/layout5/image/cache/catalog/slideshow/home4/slide2-1920x600.jpg',
    subHeading: ''
}
]