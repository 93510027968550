export const products = [{ "id": 1, "Name": "St Marys Medical Park Pharmacy", "image": "http://dummyimage.com/235x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 2, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/116x100.png/5fa2dd/ffffff", "rating": 53 },
{ "id": 3, "Name": "Laboratoires Clarins S.A.", "image": "http://dummyimage.com/183x100.png/ff4444/ffffff", "rating": 65 },
{ "id": 4, "Name": "Medtech Products Inc.", "image": "http://dummyimage.com/152x100.png/dddddd/000000", "rating": 52 },
{ "id": 5, "Name": "KAISER FOUNDATION HOSPITALS", "image": "http://dummyimage.com/244x100.png/cc0000/ffffff", "rating": 13 },
{ "id": 6, "Name": "Vienna Health and Beauty Corporation", "image": "http://dummyimage.com/172x100.png/ff4444/ffffff", "rating": 94 },
{ "id": 7, "Name": "Quality Home Products", "image": "http://dummyimage.com/204x100.png/5fa2dd/ffffff", "rating": 17 },
{ "id": 8, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/171x100.png/ff4444/ffffff", "rating": 68 },
{ "id": 9, "Name": "Hi-Tech Pharmacal Co., Inc.", "image": "http://dummyimage.com/164x100.png/ff4444/ffffff", "rating": 19 },
{ "id": 10, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/174x100.png/5fa2dd/ffffff", "rating": 36 },
{ "id": 11, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/171x100.png/dddddd/000000", "rating": 34 },
{ "id": 12, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/134x100.png/dddddd/000000", "rating": 26 },
{ "id": 13, "Name": "PuraVation Pharmaceuticals Inc", "image": "http://dummyimage.com/226x100.png/5fa2dd/ffffff", "rating": 4 },
{ "id": 14, "Name": "Contract Pharmacy Services-PA", "image": "http://dummyimage.com/172x100.png/dddddd/000000", "rating": 72 },
{ "id": 15, "Name": "McKesson Packaging Services a business unit of McKesson Corporation", "image": "http://dummyimage.com/140x100.png/ff4444/ffffff", "rating": 26 },
{ "id": 16, "Name": "Keltman Pharmaceuticals Inc.", "image": "http://dummyimage.com/242x100.png/dddddd/000000", "rating": 47 },
{ "id": 17, "Name": "AP GOLDSHIELD LLC", "image": "http://dummyimage.com/152x100.png/dddddd/000000", "rating": 8 },
{ "id": 18, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/206x100.png/5fa2dd/ffffff", "rating": 70 },
{ "id": 19, "Name": "Ulta", "image": "http://dummyimage.com/133x100.png/cc0000/ffffff", "rating": 91 },
{ "id": 20, "Name": "Clinical Solutions Wholesale", "image": "http://dummyimage.com/204x100.png/dddddd/000000", "rating": 15 },
{ "id": 21, "Name": "BCM Ltd", "image": "http://dummyimage.com/150x100.png/ff4444/ffffff", "rating": 44 },
{ "id": 22, "Name": "New World Imports, Inc", "image": "http://dummyimage.com/108x100.png/cc0000/ffffff", "rating": 23 },
{ "id": 23, "Name": "Cantrell Drug Company", "image": "http://dummyimage.com/211x100.png/cc0000/ffffff", "rating": 56 },
{ "id": 24, "Name": "Amerisource Bergen", "image": "http://dummyimage.com/203x100.png/ff4444/ffffff", "rating": 43 },
{ "id": 25, "Name": "NorthStar RxLLC", "image": "http://dummyimage.com/146x100.png/dddddd/000000", "rating": 28 },
{ "id": 26, "Name": "Allergy Laboratories, Inc.", "image": "http://dummyimage.com/216x100.png/cc0000/ffffff", "rating": 33 },
{ "id": 27, "Name": "Similasan Corporation", "image": "http://dummyimage.com/148x100.png/dddddd/000000", "rating": 44 },
{ "id": 28, "Name": "Nesher Pharmaceuticals (USA) LLC", "image": "http://dummyimage.com/124x100.png/cc0000/ffffff", "rating": 42 },
{ "id": 29, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/185x100.png/cc0000/ffffff", "rating": 1 },
{ "id": 30, "Name": "OraLabs", "image": "http://dummyimage.com/210x100.png/cc0000/ffffff", "rating": 43 },
{ "id": 31, "Name": "WG Critical Care, LLC", "image": "http://dummyimage.com/171x100.png/cc0000/ffffff", "rating": 35 },
{ "id": 32, "Name": "Heritage Pharmaceuticals Inc.", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 33, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/179x100.png/5fa2dd/ffffff", "rating": 50 },
{ "id": 34, "Name": "Apotheca Company", "image": "http://dummyimage.com/166x100.png/cc0000/ffffff", "rating": 90 },
{ "id": 35, "Name": "TJL Enterprises Inc.", "image": "http://dummyimage.com/139x100.png/5fa2dd/ffffff", "rating": 67 },
{ "id": 36, "Name": "KANEBO COSMETICS INC", "image": "http://dummyimage.com/103x100.png/ff4444/ffffff", "rating": 85 },
{ "id": 37, "Name": "Best Choice", "image": "http://dummyimage.com/118x100.png/ff4444/ffffff", "rating": 62 },
{ "id": 38, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/113x100.png/dddddd/000000", "rating": 6 },
{ "id": 39, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/222x100.png/ff4444/ffffff", "rating": 2 },
{ "id": 40, "Name": "Novartis Consumer Health", "image": "http://dummyimage.com/206x100.png/5fa2dd/ffffff", "rating": 24 },
{ "id": 41, "Name": "Acino Products, LLC.", "image": "http://dummyimage.com/145x100.png/cc0000/ffffff", "rating": 80 },
{ "id": 42, "Name": "Allergy Laboratories, Inc.", "image": "http://dummyimage.com/190x100.png/5fa2dd/ffffff", "rating": 70 },
{ "id": 43, "Name": "Amerisource Bergen", "image": "http://dummyimage.com/122x100.png/5fa2dd/ffffff", "rating": 74 },
{ "id": 44, "Name": "Home Sweet Homeopathics", "image": "http://dummyimage.com/181x100.png/cc0000/ffffff", "rating": 54 },
{ "id": 45, "Name": "MineralHouse Corporation", "image": "http://dummyimage.com/249x100.png/ff4444/ffffff", "rating": 53 },
{ "id": 46, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/148x100.png/dddddd/000000", "rating": 62 },
{ "id": 47, "Name": "ENCHANTE ACCESSORIES INC.", "image": "http://dummyimage.com/203x100.png/5fa2dd/ffffff", "rating": 59 },
{ "id": 48, "Name": "The Kroger Co.", "image": "http://dummyimage.com/205x100.png/cc0000/ffffff", "rating": 58 },
{ "id": 49, "Name": "SHISEIDO AMERICA INC.", "image": "http://dummyimage.com/124x100.png/cc0000/ffffff", "rating": 58 },
{ "id": 50, "Name": "LABORATOIRES M&L", "image": "http://dummyimage.com/247x100.png/ff4444/ffffff", "rating": 45 },
{ "id": 51, "Name": "Carver Korea Co.,Ltd.", "image": "http://dummyimage.com/182x100.png/5fa2dd/ffffff", "rating": 11 },
{ "id": 52, "Name": "Par Pharmaceutical Inc.", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 99 },
{ "id": 53, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/236x100.png/5fa2dd/ffffff", "rating": 62 },
{ "id": 54, "Name": "Rugby Laboratories", "image": "http://dummyimage.com/141x100.png/dddddd/000000", "rating": 15 },
{ "id": 55, "Name": "Lupin Pharmaceuticals, Inc.", "image": "http://dummyimage.com/242x100.png/dddddd/000000", "rating": 8 },
{ "id": 56, "Name": "AvPAK", "image": "http://dummyimage.com/218x100.png/cc0000/ffffff", "rating": 75 },
{ "id": 57, "Name": "Indiana Botanic Gardens", "image": "http://dummyimage.com/101x100.png/ff4444/ffffff", "rating": 63 },
{ "id": 58, "Name": "EMD Serono, Inc.", "image": "http://dummyimage.com/175x100.png/ff4444/ffffff", "rating": 2 },
{ "id": 59, "Name": "American Health Packaging", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 60, "Name": "Meijer Distribution Inc", "image": "http://dummyimage.com/151x100.png/ff4444/ffffff", "rating": 81 },
{ "id": 61, "Name": "HARRIS TEETER", "image": "http://dummyimage.com/169x100.png/cc0000/ffffff", "rating": 72 },
{ "id": 62, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/177x100.png/dddddd/000000", "rating": 24 },
{ "id": 63, "Name": "Onset Dermatologics LLC", "image": "http://dummyimage.com/250x100.png/dddddd/000000", "rating": 53 },
{ "id": 64, "Name": "KANEBO COSMETICS INC", "image": "http://dummyimage.com/134x100.png/ff4444/ffffff", "rating": 55 },
{ "id": 65, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/189x100.png/dddddd/000000", "rating": 46 },
{ "id": 66, "Name": "American Health Packaging", "image": "http://dummyimage.com/205x100.png/5fa2dd/ffffff", "rating": 25 },
{ "id": 67, "Name": "Preferred Pharmaceuticals, Inc.", "image": "http://dummyimage.com/247x100.png/ff4444/ffffff", "rating": 10 },
{ "id": 68, "Name": "Par Pharmaceutical, Inc", "image": "http://dummyimage.com/232x100.png/5fa2dd/ffffff", "rating": 44 },
{ "id": 69, "Name": "Micrylium Laboratories", "image": "http://dummyimage.com/145x100.png/ff4444/ffffff", "rating": 79 },
{ "id": 70, "Name": "Songjeongo OM Clinic", "image": "http://dummyimage.com/212x100.png/ff4444/ffffff", "rating": 12 },
{ "id": 71, "Name": "STAT RX USA LLC", "image": "http://dummyimage.com/179x100.png/cc0000/ffffff", "rating": 1 },
{ "id": 72, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/131x100.png/dddddd/000000", "rating": 56 },
{ "id": 73, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/212x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 74, "Name": "SHISEIDO AMERICAS CORPORATION", "image": "http://dummyimage.com/215x100.png/ff4444/ffffff", "rating": 93 },
{ "id": 75, "Name": "IASO Inc", "image": "http://dummyimage.com/158x100.png/cc0000/ffffff", "rating": 34 },
{ "id": 76, "Name": "Aplicare, Inc.", "image": "http://dummyimage.com/226x100.png/5fa2dd/ffffff", "rating": 86 },
{ "id": 77, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/245x100.png/5fa2dd/ffffff", "rating": 79 },
{ "id": 78, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/209x100.png/5fa2dd/ffffff", "rating": 35 },
{ "id": 79, "Name": "Bedford Laboratories", "image": "http://dummyimage.com/244x100.png/5fa2dd/ffffff", "rating": 59 },
{ "id": 80, "Name": "Mission Pharmacal Company", "image": "http://dummyimage.com/148x100.png/cc0000/ffffff", "rating": 69 },
{ "id": 81, "Name": "Avon Products, Inc", "image": "http://dummyimage.com/127x100.png/5fa2dd/ffffff", "rating": 62 },
{ "id": 82, "Name": "Medline Industries, Inc.", "image": "http://dummyimage.com/213x100.png/dddddd/000000", "rating": 31 },
{ "id": 83, "Name": "FAMILY DOLLAR", "image": "http://dummyimage.com/109x100.png/ff4444/ffffff", "rating": 72 },
{ "id": 84, "Name": "Celgene Corporation", "image": "http://dummyimage.com/177x100.png/dddddd/000000", "rating": 62 },
{ "id": 85, "Name": "sanofi-aventis U.S. LLC", "image": "http://dummyimage.com/200x100.png/cc0000/ffffff", "rating": 86 },
{ "id": 86, "Name": "Cardinal Health", "image": "http://dummyimage.com/123x100.png/5fa2dd/ffffff", "rating": 19 },
{ "id": 87, "Name": "West-ward Pharmaceutical Corp.", "image": "http://dummyimage.com/241x100.png/5fa2dd/ffffff", "rating": 95 },
{ "id": 88, "Name": "Dermazone Solutions, Inc.", "image": "http://dummyimage.com/111x100.png/dddddd/000000", "rating": 59 },
{ "id": 89, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/108x100.png/cc0000/ffffff", "rating": 58 },
{ "id": 90, "Name": "Mangiacotti Floral LLC", "image": "http://dummyimage.com/189x100.png/dddddd/000000", "rating": 36 },
{ "id": 91, "Name": "Sandoz Inc", "image": "http://dummyimage.com/238x100.png/ff4444/ffffff", "rating": 15 },
{ "id": 92, "Name": "Watson Laboratories, Inc.", "image": "http://dummyimage.com/200x100.png/dddddd/000000", "rating": 95 },
{ "id": 93, "Name": "Kareway Product, Inc.", "image": "http://dummyimage.com/128x100.png/cc0000/ffffff", "rating": 2 },
{ "id": 94, "Name": "Apotheca Company", "image": "http://dummyimage.com/151x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 95, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/152x100.png/ff4444/ffffff", "rating": 90 },
{ "id": 96, "Name": "Hyland's", "image": "http://dummyimage.com/216x100.png/dddddd/000000", "rating": 62 },
{ "id": 97, "Name": "Walgreens", "image": "http://dummyimage.com/135x100.png/ff4444/ffffff", "rating": 96 },
{ "id": 98, "Name": "BTA Pharmaceuticals, Inc.", "image": "http://dummyimage.com/139x100.png/cc0000/ffffff", "rating": 97 },
{ "id": 99, "Name": "Hi-Tech Pharmacal Co., Inc.", "image": "http://dummyimage.com/135x100.png/5fa2dd/ffffff", "rating": 55 },
{ "id": 100, "Name": "Ningbo Pulisi Daily Chemical Products Co., Ltd", "image": "http://dummyimage.com/115x100.png/ff4444/ffffff", "rating": 27 },
{ "id": 101, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/245x100.png/dddddd/000000", "rating": 32 },
{ "id": 102, "Name": "HOMEOLAB USA INC.", "image": "http://dummyimage.com/208x100.png/ff4444/ffffff", "rating": 85 },
{ "id": 103, "Name": "Paddock Laboratories, LLC", "image": "http://dummyimage.com/111x100.png/ff4444/ffffff", "rating": 49 },
{ "id": 104, "Name": "Siscom de Argentina S.A.", "image": "http://dummyimage.com/249x100.png/dddddd/000000", "rating": 25 },
{ "id": 105, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/101x100.png/dddddd/000000", "rating": 85 },
{ "id": 106, "Name": "Allermed Laboratories, Inc.", "image": "http://dummyimage.com/148x100.png/dddddd/000000", "rating": 32 },
{ "id": 107, "Name": "CLINIQUE LABORATORIES INC", "image": "http://dummyimage.com/153x100.png/5fa2dd/ffffff", "rating": 39 },
{ "id": 108, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/167x100.png/ff4444/ffffff", "rating": 19 },
{ "id": 109, "Name": "Cypress Pharmaceutical, Inc.", "image": "http://dummyimage.com/225x100.png/5fa2dd/ffffff", "rating": 41 },
{ "id": 110, "Name": "Ventura International LTD.", "image": "http://dummyimage.com/173x100.png/cc0000/ffffff", "rating": 10 },
{ "id": 111, "Name": "Jets, Sets, & Elephants Beauty Corp.", "image": "http://dummyimage.com/245x100.png/cc0000/ffffff", "rating": 33 },
{ "id": 112, "Name": "RedPharm Drug Inc.", "image": "http://dummyimage.com/111x100.png/cc0000/ffffff", "rating": 4 },
{ "id": 113, "Name": "Chain Drug Marketing Association", "image": "http://dummyimage.com/175x100.png/ff4444/ffffff", "rating": 29 },
{ "id": 114, "Name": "Hyland's", "image": "http://dummyimage.com/140x100.png/dddddd/000000", "rating": 50 },
{ "id": 115, "Name": "Clinical Solutions Wholesale", "image": "http://dummyimage.com/249x100.png/cc0000/ffffff", "rating": 44 },
{ "id": 116, "Name": "Palmer Fixture Company", "image": "http://dummyimage.com/217x100.png/ff4444/ffffff", "rating": 29 },
{ "id": 117, "Name": "Caraco Pharmaceutical Laboratories, Ltd.", "image": "http://dummyimage.com/155x100.png/ff4444/ffffff", "rating": 42 },
{ "id": 118, "Name": "Natural Health Supply", "image": "http://dummyimage.com/152x100.png/cc0000/ffffff", "rating": 51 },
{ "id": 119, "Name": "STAT Rx USA LLC", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 34 },
{ "id": 120, "Name": "SJ Creations, Inc.", "image": "http://dummyimage.com/204x100.png/dddddd/000000", "rating": 64 },
{ "id": 121, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/111x100.png/cc0000/ffffff", "rating": 50 },
{ "id": 122, "Name": "American Health Packaging", "image": "http://dummyimage.com/246x100.png/ff4444/ffffff", "rating": 3 },
{ "id": 123, "Name": "Proficient Rx LP", "image": "http://dummyimage.com/232x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 124, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/229x100.png/dddddd/000000", "rating": 98 },
{ "id": 125, "Name": "Safeway Inc", "image": "http://dummyimage.com/123x100.png/5fa2dd/ffffff", "rating": 30 },
{ "id": 126, "Name": "Conopco Inc. d/b/a Unilever", "image": "http://dummyimage.com/186x100.png/5fa2dd/ffffff", "rating": 74 },
{ "id": 127, "Name": "Sun Pharmaceutical Industries, Inc.", "image": "http://dummyimage.com/133x100.png/dddddd/000000", "rating": 35 },
{ "id": 128, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/173x100.png/cc0000/ffffff", "rating": 29 },
{ "id": 129, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/101x100.png/dddddd/000000", "rating": 93 },
{ "id": 130, "Name": "WOONSOCKET PRESCRIPTION CENTER,INCORPORATED", "image": "http://dummyimage.com/225x100.png/5fa2dd/ffffff", "rating": 35 },
{ "id": 131, "Name": "Preferred Pharmaceuticals, Inc", "image": "http://dummyimage.com/230x100.png/ff4444/ffffff", "rating": 44 },
{ "id": 132, "Name": "Amneal Pharmaceuticals", "image": "http://dummyimage.com/152x100.png/ff4444/ffffff", "rating": 31 },
{ "id": 133, "Name": "Unit Dose Services", "image": "http://dummyimage.com/124x100.png/ff4444/ffffff", "rating": 22 },
{ "id": 134, "Name": "Uriel Pharmacy Inc", "image": "http://dummyimage.com/129x100.png/ff4444/ffffff", "rating": 85 },
{ "id": 135, "Name": "Rising Pharmaceuticals Inc", "image": "http://dummyimage.com/106x100.png/dddddd/000000", "rating": 27 },
{ "id": 136, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/118x100.png/5fa2dd/ffffff", "rating": 51 },
{ "id": 137, "Name": "Janssen Pharmaceuticals, Inc.", "image": "http://dummyimage.com/137x100.png/cc0000/ffffff", "rating": 97 },
{ "id": 138, "Name": "ReadyMeds", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 139, "Name": "Zenith Medicosm SL", "image": "http://dummyimage.com/160x100.png/dddddd/000000", "rating": 93 },
{ "id": 140, "Name": "WOONSOCKET PRESCRIPTION CENTER,INCORPORATED", "image": "http://dummyimage.com/172x100.png/cc0000/ffffff", "rating": 90 },
{ "id": 141, "Name": "AvKARE, Inc.", "image": "http://dummyimage.com/250x100.png/ff4444/ffffff", "rating": 72 },
{ "id": 142, "Name": "Liddell Laboratories", "image": "http://dummyimage.com/114x100.png/ff4444/ffffff", "rating": 71 },
{ "id": 143, "Name": "West-Ward Pharmaceutical Corp", "image": "http://dummyimage.com/159x100.png/dddddd/000000", "rating": 90 },
{ "id": 144, "Name": "Sato Pharmaceutical Co., Ltd.", "image": "http://dummyimage.com/218x100.png/ff4444/ffffff", "rating": 78 },
{ "id": 145, "Name": "Cintas Corp.", "image": "http://dummyimage.com/172x100.png/5fa2dd/ffffff", "rating": 95 },
{ "id": 146, "Name": "Forest Laboratories", "image": "http://dummyimage.com/145x100.png/5fa2dd/ffffff", "rating": 13 },
{ "id": 147, "Name": "McKesson", "image": "http://dummyimage.com/218x100.png/cc0000/ffffff", "rating": 100 },
{ "id": 148, "Name": "Rebel Distributors Corp.", "image": "http://dummyimage.com/230x100.png/ff4444/ffffff", "rating": 36 },
{ "id": 149, "Name": "THEFACESHOP CO., LTD.", "image": "http://dummyimage.com/140x100.png/5fa2dd/ffffff", "rating": 13 },
{ "id": 150, "Name": "Rugby Laboratories", "image": "http://dummyimage.com/183x100.png/cc0000/ffffff", "rating": 67 },
{ "id": 151, "Name": "Zydus Pharmaceuticals (USA) Inc.", "image": "http://dummyimage.com/226x100.png/dddddd/000000", "rating": 52 },
{ "id": 152, "Name": "Koh Gen Do USA, Inc", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 48 },
{ "id": 153, "Name": "Hyland's", "image": "http://dummyimage.com/163x100.png/ff4444/ffffff", "rating": 61 },
{ "id": 154, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/115x100.png/ff4444/ffffff", "rating": 34 },
{ "id": 155, "Name": "AvKARE, Inc.", "image": "http://dummyimage.com/102x100.png/ff4444/ffffff", "rating": 56 },
{ "id": 156, "Name": "A-S Medication Solutions LLC", "image": "http://dummyimage.com/146x100.png/5fa2dd/ffffff", "rating": 46 },
{ "id": 157, "Name": "Actavis Pharma, Inc.", "image": "http://dummyimage.com/121x100.png/5fa2dd/ffffff", "rating": 24 },
{ "id": 158, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/162x100.png/cc0000/ffffff", "rating": 58 },
{ "id": 159, "Name": "Ventura Corporation, Ltd.", "image": "http://dummyimage.com/219x100.png/5fa2dd/ffffff", "rating": 23 },
{ "id": 160, "Name": "ARMY AND AIR FORCE EXCHANGE SERVICE", "image": "http://dummyimage.com/248x100.png/5fa2dd/ffffff", "rating": 67 },
{ "id": 161, "Name": "Laboratoires Clarins S.A", "image": "http://dummyimage.com/239x100.png/cc0000/ffffff", "rating": 55 },
{ "id": 162, "Name": "The Mentholatum Company", "image": "http://dummyimage.com/213x100.png/5fa2dd/ffffff", "rating": 60 },
{ "id": 163, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/124x100.png/cc0000/ffffff", "rating": 59 },
{ "id": 164, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/221x100.png/ff4444/ffffff", "rating": 31 },
{ "id": 165, "Name": "Medsep Corporation", "image": "http://dummyimage.com/160x100.png/dddddd/000000", "rating": 61 },
{ "id": 166, "Name": "Energique, Inc.", "image": "http://dummyimage.com/118x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 167, "Name": "WellSpring Pharmaceutical Corporation", "image": "http://dummyimage.com/245x100.png/5fa2dd/ffffff", "rating": 44 },
{ "id": 168, "Name": "Cephalon, Inc.", "image": "http://dummyimage.com/200x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 169, "Name": "NCS HealthCare of KY, Inc dba Vangard Labs", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 58 },
{ "id": 170, "Name": "C.B. Fleet Company, Inc.", "image": "http://dummyimage.com/160x100.png/ff4444/ffffff", "rating": 60 },
{ "id": 171, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/243x100.png/cc0000/ffffff", "rating": 51 },
{ "id": 172, "Name": "CREEKWOOD PHARMACEUTICAL, INC,", "image": "http://dummyimage.com/155x100.png/cc0000/ffffff", "rating": 46 },
{ "id": 173, "Name": "Proficient Rx LP", "image": "http://dummyimage.com/239x100.png/ff4444/ffffff", "rating": 20 },
{ "id": 174, "Name": "GUILIN TIANHE PHARMACEUTICAL CO LTD", "image": "http://dummyimage.com/212x100.png/cc0000/ffffff", "rating": 12 },
{ "id": 175, "Name": "KJI Industrial Co Ltd", "image": "http://dummyimage.com/158x100.png/5fa2dd/ffffff", "rating": 21 },
{ "id": 176, "Name": "CHAIN DRUG MARKETING ASSOCIATION INC", "image": "http://dummyimage.com/240x100.png/cc0000/ffffff", "rating": 75 },
{ "id": 177, "Name": "State of Florida DOH Central Pharmacy", "image": "http://dummyimage.com/229x100.png/dddddd/000000", "rating": 41 },
{ "id": 178, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/215x100.png/cc0000/ffffff", "rating": 62 },
{ "id": 179, "Name": "Aurobindo Pharma Limited", "image": "http://dummyimage.com/135x100.png/ff4444/ffffff", "rating": 4 },
{ "id": 180, "Name": "Best Choice", "image": "http://dummyimage.com/174x100.png/5fa2dd/ffffff", "rating": 96 },
{ "id": 181, "Name": "Preferred Pharmaceuticals, Inc", "image": "http://dummyimage.com/141x100.png/cc0000/ffffff", "rating": 57 },
{ "id": 182, "Name": "NANDA CO., LTD.", "image": "http://dummyimage.com/223x100.png/5fa2dd/ffffff", "rating": 18 },
{ "id": 183, "Name": "West-Ward Pharmaceutical Corp", "image": "http://dummyimage.com/168x100.png/cc0000/ffffff", "rating": 75 },
{ "id": 184, "Name": "Water-Jel Technologies", "image": "http://dummyimage.com/188x100.png/dddddd/000000", "rating": 100 },
{ "id": 185, "Name": "Dispensing Solutions, Inc.", "image": "http://dummyimage.com/223x100.png/cc0000/ffffff", "rating": 9 },
{ "id": 186, "Name": "Par Pharmaceutical Inc", "image": "http://dummyimage.com/214x100.png/dddddd/000000", "rating": 25 },
{ "id": 187, "Name": "SUPERVALU INC.", "image": "http://dummyimage.com/203x100.png/5fa2dd/ffffff", "rating": 26 },
{ "id": 188, "Name": "Dispensing Solutions, Inc.", "image": "http://dummyimage.com/151x100.png/dddddd/000000", "rating": 91 },
{ "id": 189, "Name": "Browning's Pharmacy and Healthcare Inc.", "image": "http://dummyimage.com/117x100.png/5fa2dd/ffffff", "rating": 19 },
{ "id": 190, "Name": "Meda Consumer Healthcare Inc.", "image": "http://dummyimage.com/158x100.png/5fa2dd/ffffff", "rating": 72 },
{ "id": 191, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/105x100.png/cc0000/ffffff", "rating": 23 },
{ "id": 192, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/142x100.png/cc0000/ffffff", "rating": 67 },
{ "id": 193, "Name": "Aidarex Pharmaceuticals LLC", "image": "http://dummyimage.com/126x100.png/ff4444/ffffff", "rating": 47 },
{ "id": 194, "Name": "Good Sense (Geiss, Destin & Dunn, Inc.)", "image": "http://dummyimage.com/111x100.png/dddddd/000000", "rating": 29 },
{ "id": 195, "Name": "Amerisource Bergen", "image": "http://dummyimage.com/245x100.png/dddddd/000000", "rating": 68 },
{ "id": 196, "Name": "Kama Sutra", "image": "http://dummyimage.com/243x100.png/5fa2dd/ffffff", "rating": 100 },
{ "id": 197, "Name": "Johnson & Johnson Consumer Products Company, Division of Johnson & Johnson Consumer Companies, Inc.", "image": "http://dummyimage.com/203x100.png/ff4444/ffffff", "rating": 22 },
{ "id": 198, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/219x100.png/5fa2dd/ffffff", "rating": 28 },
{ "id": 199, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/247x100.png/5fa2dd/ffffff", "rating": 87 },
{ "id": 200, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/247x100.png/dddddd/000000", "rating": 68 },
{ "id": 201, "Name": "Walgreen Company", "image": "http://dummyimage.com/107x100.png/cc0000/ffffff", "rating": 39 },
{ "id": 202, "Name": "Cobalt Laboratories Inc.", "image": "http://dummyimage.com/244x100.png/5fa2dd/ffffff", "rating": 56 },
{ "id": 203, "Name": "Walgreen Company", "image": "http://dummyimage.com/129x100.png/dddddd/000000", "rating": 54 },
{ "id": 204, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 55 },
{ "id": 205, "Name": "Wockhardt Limited", "image": "http://dummyimage.com/152x100.png/cc0000/ffffff", "rating": 15 },
{ "id": 206, "Name": "Doori Cosmetics Co., Ltd", "image": "http://dummyimage.com/122x100.png/dddddd/000000", "rating": 25 },
{ "id": 207, "Name": "BioActive Nutritional", "image": "http://dummyimage.com/127x100.png/ff4444/ffffff", "rating": 63 },
{ "id": 208, "Name": "Pfizer Laboratories Div Pfizer Inc", "image": "http://dummyimage.com/155x100.png/5fa2dd/ffffff", "rating": 74 },
{ "id": 209, "Name": "PharmaDerm A division of Fougera Pharmaceuticals Inc.", "image": "http://dummyimage.com/229x100.png/dddddd/000000", "rating": 77 },
{ "id": 210, "Name": "The Doctor's Cosmetic Inc", "image": "http://dummyimage.com/121x100.png/dddddd/000000", "rating": 31 },
{ "id": 211, "Name": "SiCap Industries LLC", "image": "http://dummyimage.com/176x100.png/ff4444/ffffff", "rating": 4 },
{ "id": 212, "Name": "West-Ward Pharmaceutical Corp", "image": "http://dummyimage.com/145x100.png/ff4444/ffffff", "rating": 60 },
{ "id": 213, "Name": "Preferred Pharmaceuticals, Inc.", "image": "http://dummyimage.com/243x100.png/ff4444/ffffff", "rating": 18 },
{ "id": 214, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/119x100.png/5fa2dd/ffffff", "rating": 81 },
{ "id": 215, "Name": "Affordable Pharmaceuticals, LLC", "image": "http://dummyimage.com/178x100.png/ff4444/ffffff", "rating": 60 },
{ "id": 216, "Name": "King Bio Inc.", "image": "http://dummyimage.com/191x100.png/ff4444/ffffff", "rating": 26 },
{ "id": 217, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/172x100.png/ff4444/ffffff", "rating": 50 },
{ "id": 218, "Name": "Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/217x100.png/5fa2dd/ffffff", "rating": 34 },
{ "id": 219, "Name": "Ecolab Inc.", "image": "http://dummyimage.com/204x100.png/cc0000/ffffff", "rating": 69 },
{ "id": 220, "Name": "Eon Labs, Inc.", "image": "http://dummyimage.com/167x100.png/ff4444/ffffff", "rating": 66 },
{ "id": 221, "Name": "Actavis Inc", "image": "http://dummyimage.com/227x100.png/5fa2dd/ffffff", "rating": 92 },
{ "id": 222, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/178x100.png/5fa2dd/ffffff", "rating": 91 },
{ "id": 223, "Name": "Barr Laboratories Inc.", "image": "http://dummyimage.com/111x100.png/ff4444/ffffff", "rating": 2 },
{ "id": 224, "Name": "BTA Pharmaceuticals", "image": "http://dummyimage.com/114x100.png/cc0000/ffffff", "rating": 51 },
{ "id": 225, "Name": "Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/231x100.png/dddddd/000000", "rating": 45 },
{ "id": 226, "Name": "Avon Products, Inc.", "image": "http://dummyimage.com/180x100.png/dddddd/000000", "rating": 100 },
{ "id": 227, "Name": "Novadaq Technologies Inc.", "image": "http://dummyimage.com/103x100.png/5fa2dd/ffffff", "rating": 1 },
{ "id": 228, "Name": "H.J. Harkins Company, Inc.", "image": "http://dummyimage.com/212x100.png/ff4444/ffffff", "rating": 24 },
{ "id": 229, "Name": "Bare Escentuals Beauty Inc.", "image": "http://dummyimage.com/142x100.png/dddddd/000000", "rating": 25 },
{ "id": 230, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/114x100.png/5fa2dd/ffffff", "rating": 61 },
{ "id": 231, "Name": "Yves Rocher North America Inc", "image": "http://dummyimage.com/169x100.png/ff4444/ffffff", "rating": 13 },
{ "id": 232, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/151x100.png/5fa2dd/ffffff", "rating": 34 },
{ "id": 233, "Name": "Jiangsu Province JianErKang Medical Dressing Co.,Ltd", "image": "http://dummyimage.com/127x100.png/5fa2dd/ffffff", "rating": 93 },
{ "id": 234, "Name": "Western Family Foods Inc", "image": "http://dummyimage.com/161x100.png/ff4444/ffffff", "rating": 2 },
{ "id": 235, "Name": "Kay Chemical", "image": "http://dummyimage.com/165x100.png/5fa2dd/ffffff", "rating": 48 },
{ "id": 236, "Name": "Mondelez Global LLC", "image": "http://dummyimage.com/163x100.png/5fa2dd/ffffff", "rating": 50 },
{ "id": 237, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/203x100.png/ff4444/ffffff", "rating": 66 },
{ "id": 238, "Name": "NCS HealthCare of KY, Inc dba Vangard Labs", "image": "http://dummyimage.com/166x100.png/5fa2dd/ffffff", "rating": 94 },
{ "id": 239, "Name": "American Health Packaging", "image": "http://dummyimage.com/239x100.png/5fa2dd/ffffff", "rating": 3 },
{ "id": 240, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/214x100.png/dddddd/000000", "rating": 70 },
{ "id": 241, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/243x100.png/dddddd/000000", "rating": 80 },
{ "id": 242, "Name": "DZA Brands LLC", "image": "http://dummyimage.com/232x100.png/ff4444/ffffff", "rating": 82 },
{ "id": 243, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/209x100.png/ff4444/ffffff", "rating": 20 },
{ "id": 244, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/176x100.png/5fa2dd/ffffff", "rating": 63 },
{ "id": 245, "Name": "The Hain Celestial Group, Inc.", "image": "http://dummyimage.com/134x100.png/cc0000/ffffff", "rating": 68 },
{ "id": 246, "Name": "National Towelette Company", "image": "http://dummyimage.com/197x100.png/5fa2dd/ffffff", "rating": 27 },
{ "id": 247, "Name": "Enzyme Solutions, Inc.", "image": "http://dummyimage.com/247x100.png/dddddd/000000", "rating": 10 },
{ "id": 248, "Name": "Contract Pharmacy Services-PA", "image": "http://dummyimage.com/243x100.png/dddddd/000000", "rating": 68 },
{ "id": 249, "Name": "Par Pharmaceutical Companies, Inc.", "image": "http://dummyimage.com/150x100.png/dddddd/000000", "rating": 59 },
{ "id": 250, "Name": "Ventura Corporation Ltd.", "image": "http://dummyimage.com/215x100.png/5fa2dd/ffffff", "rating": 44 },
{ "id": 251, "Name": "GW Laboratories, Inc.", "image": "http://dummyimage.com/244x100.png/dddddd/000000", "rating": 17 },
{ "id": 252, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/243x100.png/5fa2dd/ffffff", "rating": 51 },
{ "id": 253, "Name": "Rebel Distributors Corp.", "image": "http://dummyimage.com/186x100.png/cc0000/ffffff", "rating": 5 },
{ "id": 254, "Name": "TMIG, Inc.", "image": "http://dummyimage.com/131x100.png/ff4444/ffffff", "rating": 58 },
{ "id": 255, "Name": "Cardinal Health", "image": "http://dummyimage.com/246x100.png/5fa2dd/ffffff", "rating": 67 },
{ "id": 256, "Name": "Energizer Personal Care LLC", "image": "http://dummyimage.com/115x100.png/dddddd/000000", "rating": 13 },
{ "id": 257, "Name": "Benco Dental", "image": "http://dummyimage.com/139x100.png/dddddd/000000", "rating": 68 },
{ "id": 258, "Name": "Walgreen Company", "image": "http://dummyimage.com/119x100.png/ff4444/ffffff", "rating": 82 },
{ "id": 259, "Name": "American Health Packaging", "image": "http://dummyimage.com/197x100.png/cc0000/ffffff", "rating": 64 },
{ "id": 260, "Name": "GE Healthcare Inc.", "image": "http://dummyimage.com/101x100.png/ff4444/ffffff", "rating": 71 },
{ "id": 261, "Name": "Apotex Corp.", "image": "http://dummyimage.com/181x100.png/ff4444/ffffff", "rating": 87 },
{ "id": 262, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/200x100.png/ff4444/ffffff", "rating": 8 },
{ "id": 263, "Name": "McKesson", "image": "http://dummyimage.com/219x100.png/ff4444/ffffff", "rating": 99 },
{ "id": 264, "Name": "Aphena Pharma Solutions - Tennessee, Inc.", "image": "http://dummyimage.com/187x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 265, "Name": "HYVEE INC.", "image": "http://dummyimage.com/138x100.png/dddddd/000000", "rating": 74 },
{ "id": 266, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/240x100.png/ff4444/ffffff", "rating": 9 },
{ "id": 267, "Name": "Preferred Pharmaceuticals, Inc.", "image": "http://dummyimage.com/240x100.png/5fa2dd/ffffff", "rating": 86 },
{ "id": 268, "Name": "SUPERVALU INC", "image": "http://dummyimage.com/238x100.png/ff4444/ffffff", "rating": 49 },
{ "id": 269, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/164x100.png/cc0000/ffffff", "rating": 61 },
{ "id": 270, "Name": "SUN PHARMACEUTICAL INDUSTRIES LIMITED", "image": "http://dummyimage.com/154x100.png/dddddd/000000", "rating": 35 },
{ "id": 271, "Name": "Target Corporation", "image": "http://dummyimage.com/157x100.png/ff4444/ffffff", "rating": 13 },
{ "id": 272, "Name": "AbbVie Inc.", "image": "http://dummyimage.com/115x100.png/cc0000/ffffff", "rating": 4 },
{ "id": 273, "Name": "H E B", "image": "http://dummyimage.com/212x100.png/cc0000/ffffff", "rating": 97 },
{ "id": 274, "Name": "Actavis Mid Atlantic LLC", "image": "http://dummyimage.com/195x100.png/cc0000/ffffff", "rating": 88 },
{ "id": 275, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/183x100.png/cc0000/ffffff", "rating": 10 },
{ "id": 276, "Name": "AnazaoHealth Corporation", "image": "http://dummyimage.com/228x100.png/ff4444/ffffff", "rating": 77 },
{ "id": 277, "Name": "HOMEOLAB USA INC.", "image": "http://dummyimage.com/192x100.png/dddddd/000000", "rating": 62 },
{ "id": 278, "Name": "Wyeth Pharmaceuticals Inc., a subsidiary of Pfizer Inc.", "image": "http://dummyimage.com/202x100.png/ff4444/ffffff", "rating": 64 },
{ "id": 279, "Name": "A-S Medication Solutions LLC", "image": "http://dummyimage.com/202x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 280, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/148x100.png/dddddd/000000", "rating": 5 },
{ "id": 281, "Name": "Rugby Laboratories", "image": "http://dummyimage.com/235x100.png/ff4444/ffffff", "rating": 79 },
{ "id": 282, "Name": "Cypress Pharmaceutical, Inc.", "image": "http://dummyimage.com/153x100.png/5fa2dd/ffffff", "rating": 32 },
{ "id": 283, "Name": "Apotheca Company", "image": "http://dummyimage.com/231x100.png/dddddd/000000", "rating": 49 },
{ "id": 284, "Name": "Uriel Pharmacy Inc", "image": "http://dummyimage.com/158x100.png/cc0000/ffffff", "rating": 89 },
{ "id": 285, "Name": "Cardinal Health", "image": "http://dummyimage.com/236x100.png/ff4444/ffffff", "rating": 31 },
{ "id": 286, "Name": "RedPharm Drug Inc.", "image": "http://dummyimage.com/190x100.png/dddddd/000000", "rating": 50 },
{ "id": 287, "Name": "Sandoz Inc", "image": "http://dummyimage.com/196x100.png/cc0000/ffffff", "rating": 81 },
{ "id": 288, "Name": "HOMEOLAB USA INC.", "image": "http://dummyimage.com/243x100.png/ff4444/ffffff", "rating": 18 },
{ "id": 289, "Name": "SAM'S WEST INC.", "image": "http://dummyimage.com/230x100.png/dddddd/000000", "rating": 12 },
{ "id": 290, "Name": "NATURE REPUBLIC CO., LTD.", "image": "http://dummyimage.com/182x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 291, "Name": "The Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/159x100.png/5fa2dd/ffffff", "rating": 22 },
{ "id": 292, "Name": "NeoPharm Co., Ltd", "image": "http://dummyimage.com/151x100.png/cc0000/ffffff", "rating": 79 },
{ "id": 293, "Name": "Merck Sharp & Dohme Corp.", "image": "http://dummyimage.com/141x100.png/ff4444/ffffff", "rating": 71 },
{ "id": 294, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/108x100.png/dddddd/000000", "rating": 63 },
{ "id": 295, "Name": "WALGREEN CO.", "image": "http://dummyimage.com/223x100.png/dddddd/000000", "rating": 51 },
{ "id": 296, "Name": "James Alexander Corporation", "image": "http://dummyimage.com/156x100.png/ff4444/ffffff", "rating": 8 },
{ "id": 297, "Name": "Denison Pharmaceuticals, Inc.", "image": "http://dummyimage.com/190x100.png/cc0000/ffffff", "rating": 27 },
{ "id": 298, "Name": "Ajou Medics Co., Ltd", "image": "http://dummyimage.com/202x100.png/cc0000/ffffff", "rating": 33 },
{ "id": 299, "Name": "Nephron Pharmaceuticals Corporation", "image": "http://dummyimage.com/171x100.png/5fa2dd/ffffff", "rating": 71 },
{ "id": 300, "Name": "ENCHANTE ACCESSORIES INC.", "image": "http://dummyimage.com/193x100.png/ff4444/ffffff", "rating": 35 },
{ "id": 301, "Name": "Swiss-American Products", "image": "http://dummyimage.com/165x100.png/5fa2dd/ffffff", "rating": 89 },
{ "id": 302, "Name": "The Kroger Co.", "image": "http://dummyimage.com/149x100.png/5fa2dd/ffffff", "rating": 99 },
{ "id": 303, "Name": "Blacksmith Brands, Inc.", "image": "http://dummyimage.com/135x100.png/cc0000/ffffff", "rating": 96 },
{ "id": 304, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/119x100.png/5fa2dd/ffffff", "rating": 81 },
{ "id": 305, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/209x100.png/cc0000/ffffff", "rating": 74 },
{ "id": 306, "Name": "Topco Associates LLC", "image": "http://dummyimage.com/137x100.png/5fa2dd/ffffff", "rating": 27 },
{ "id": 307, "Name": "Dynarex Corporation", "image": "http://dummyimage.com/123x100.png/cc0000/ffffff", "rating": 65 },
{ "id": 308, "Name": "NATURE REPUBLIC CO., LTD.", "image": "http://dummyimage.com/213x100.png/ff4444/ffffff", "rating": 63 },
{ "id": 309, "Name": "Cobalt Laboratories", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 84 },
{ "id": 310, "Name": "Advanced Generic Corporation", "image": "http://dummyimage.com/137x100.png/dddddd/000000", "rating": 83 },
{ "id": 311, "Name": "ZURICH MEDICAL LABS, LLC", "image": "http://dummyimage.com/139x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 312, "Name": "Perrigo New York Inc", "image": "http://dummyimage.com/188x100.png/dddddd/000000", "rating": 40 },
{ "id": 313, "Name": "Coty US LLC", "image": "http://dummyimage.com/248x100.png/5fa2dd/ffffff", "rating": 17 },
{ "id": 314, "Name": "Major Pharmaceuticals", "image": "http://dummyimage.com/156x100.png/dddddd/000000", "rating": 8 },
{ "id": 315, "Name": "Pharmacia and Upjohn Company", "image": "http://dummyimage.com/170x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 316, "Name": "Ecolab Inc.", "image": "http://dummyimage.com/217x100.png/ff4444/ffffff", "rating": 3 },
{ "id": 317, "Name": "Merck Sharp & Dohme Corp.", "image": "http://dummyimage.com/217x100.png/ff4444/ffffff", "rating": 64 },
{ "id": 318, "Name": "Atlantic Biologicals Corps", "image": "http://dummyimage.com/163x100.png/5fa2dd/ffffff", "rating": 27 },
{ "id": 319, "Name": "Walgreen Co.", "image": "http://dummyimage.com/115x100.png/ff4444/ffffff", "rating": 28 },
{ "id": 320, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 73 },
{ "id": 321, "Name": "Ventura Corporation (San Juan, P.R)", "image": "http://dummyimage.com/143x100.png/5fa2dd/ffffff", "rating": 70 },
{ "id": 322, "Name": "Amgen Inc", "image": "http://dummyimage.com/141x100.png/5fa2dd/ffffff", "rating": 66 },
{ "id": 323, "Name": "Apotheca Company", "image": "http://dummyimage.com/225x100.png/cc0000/ffffff", "rating": 34 },
{ "id": 324, "Name": "Uriel Pharmacy Inc.", "image": "http://dummyimage.com/232x100.png/5fa2dd/ffffff", "rating": 50 },
{ "id": 325, "Name": "GlaxoSmithKline LLC", "image": "http://dummyimage.com/170x100.png/dddddd/000000", "rating": 25 },
{ "id": 326, "Name": "Dispensing Solutions, Inc.", "image": "http://dummyimage.com/243x100.png/dddddd/000000", "rating": 28 },
{ "id": 327, "Name": "Greenstone LLC", "image": "http://dummyimage.com/135x100.png/dddddd/000000", "rating": 7 },
{ "id": 328, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/173x100.png/dddddd/000000", "rating": 69 },
{ "id": 329, "Name": "H E B", "image": "http://dummyimage.com/130x100.png/dddddd/000000", "rating": 71 },
{ "id": 330, "Name": "Ventura Corporation (San Juan, P.R)", "image": "http://dummyimage.com/230x100.png/ff4444/ffffff", "rating": 28 },
{ "id": 331, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/221x100.png/ff4444/ffffff", "rating": 5 },
{ "id": 332, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/112x100.png/cc0000/ffffff", "rating": 65 },
{ "id": 333, "Name": "Wockhardt USA LLC.", "image": "http://dummyimage.com/179x100.png/cc0000/ffffff", "rating": 78 },
{ "id": 334, "Name": "West-ward Pharmaceutical Corp.", "image": "http://dummyimage.com/222x100.png/ff4444/ffffff", "rating": 91 },
{ "id": 335, "Name": "Weimei Lianyungang Household Articles manufacturing, Co. LTD", "image": "http://dummyimage.com/214x100.png/cc0000/ffffff", "rating": 82 },
{ "id": 336, "Name": "Energique, Inc.", "image": "http://dummyimage.com/136x100.png/cc0000/ffffff", "rating": 80 },
{ "id": 337, "Name": "AnazaoHealth Corporation", "image": "http://dummyimage.com/216x100.png/cc0000/ffffff", "rating": 76 },
{ "id": 338, "Name": "Jafra cosmetics International", "image": "http://dummyimage.com/242x100.png/ff4444/ffffff", "rating": 24 },
{ "id": 339, "Name": "UNITED EXCHANGE CORP.", "image": "http://dummyimage.com/114x100.png/5fa2dd/ffffff", "rating": 34 },
{ "id": 340, "Name": "Gavis Pharmaceuticals, LLC.", "image": "http://dummyimage.com/118x100.png/cc0000/ffffff", "rating": 16 },
{ "id": 341, "Name": "Crosstex International Inc.", "image": "http://dummyimage.com/145x100.png/cc0000/ffffff", "rating": 91 },
{ "id": 342, "Name": "Cadila Healthcare Limited", "image": "http://dummyimage.com/148x100.png/ff4444/ffffff", "rating": 38 },
{ "id": 343, "Name": "NCS HealthCare of KY, Inc dba Vangard Labs", "image": "http://dummyimage.com/233x100.png/dddddd/000000", "rating": 68 },
{ "id": 344, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/244x100.png/ff4444/ffffff", "rating": 74 },
{ "id": 345, "Name": "Pure Source Inc", "image": "http://dummyimage.com/200x100.png/dddddd/000000", "rating": 92 },
{ "id": 346, "Name": "Pharmacal-International. Co., Ltd.", "image": "http://dummyimage.com/148x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 347, "Name": "Topco Associates LLC", "image": "http://dummyimage.com/229x100.png/ff4444/ffffff", "rating": 29 },
{ "id": 348, "Name": "Western Family Foods Inc", "image": "http://dummyimage.com/100x100.png/5fa2dd/ffffff", "rating": 15 },
{ "id": 349, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/237x100.png/dddddd/000000", "rating": 83 },
{ "id": 350, "Name": "A&Z Pharmaceutical, Inc.", "image": "http://dummyimage.com/141x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 351, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/118x100.png/ff4444/ffffff", "rating": 98 },
{ "id": 352, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/125x100.png/5fa2dd/ffffff", "rating": 58 },
{ "id": 353, "Name": "Massco Dental A Division of Dunagin Pharmaceuticals", "image": "http://dummyimage.com/124x100.png/ff4444/ffffff", "rating": 59 },
{ "id": 354, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/177x100.png/5fa2dd/ffffff", "rating": 10 },
{ "id": 355, "Name": "Rite Aid Corporation", "image": "http://dummyimage.com/119x100.png/ff4444/ffffff", "rating": 100 },
{ "id": 356, "Name": "Deliz Pharmaceutical Corp", "image": "http://dummyimage.com/159x100.png/5fa2dd/ffffff", "rating": 7 },
{ "id": 357, "Name": "Gremed Manufacturing LLC", "image": "http://dummyimage.com/180x100.png/cc0000/ffffff", "rating": 54 },
{ "id": 358, "Name": "Wal-Mart Stores Inc", "image": "http://dummyimage.com/129x100.png/ff4444/ffffff", "rating": 54 },
{ "id": 359, "Name": "Ventura Corporation LTD", "image": "http://dummyimage.com/135x100.png/dddddd/000000", "rating": 83 },
{ "id": 360, "Name": "Camellix, LLC", "image": "http://dummyimage.com/216x100.png/cc0000/ffffff", "rating": 84 },
{ "id": 361, "Name": "Amneal Pharmaceuticals of New York, LLC", "image": "http://dummyimage.com/195x100.png/ff4444/ffffff", "rating": 13 },
{ "id": 362, "Name": "Energique, Inc.", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 14 },
{ "id": 363, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/160x100.png/ff4444/ffffff", "rating": 7 },
{ "id": 364, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/100x100.png/dddddd/000000", "rating": 90 },
{ "id": 365, "Name": "Watson Pharma, Inc.", "image": "http://dummyimage.com/200x100.png/5fa2dd/ffffff", "rating": 40 },
{ "id": 366, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/227x100.png/dddddd/000000", "rating": 12 },
{ "id": 367, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/184x100.png/5fa2dd/ffffff", "rating": 76 },
{ "id": 368, "Name": "SOMBRA COSMETICS INC.", "image": "http://dummyimage.com/248x100.png/5fa2dd/ffffff", "rating": 84 },
{ "id": 369, "Name": "Bare Escentuals Beauty, Inc.", "image": "http://dummyimage.com/108x100.png/dddddd/000000", "rating": 22 },
{ "id": 370, "Name": "E. Fougera & Co. a division of Fougera Pharmaceuticals Inc.", "image": "http://dummyimage.com/124x100.png/dddddd/000000", "rating": 13 },
{ "id": 371, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/219x100.png/ff4444/ffffff", "rating": 100 },
{ "id": 372, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/233x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 373, "Name": "Capricorn Pharma Inc.", "image": "http://dummyimage.com/119x100.png/dddddd/000000", "rating": 14 },
{ "id": 374, "Name": "LG Household and Healthcare, Inc.", "image": "http://dummyimage.com/249x100.png/cc0000/ffffff", "rating": 64 },
{ "id": 375, "Name": "Cardinal Health", "image": "http://dummyimage.com/122x100.png/dddddd/000000", "rating": 61 },
{ "id": 376, "Name": "philosophy", "image": "http://dummyimage.com/144x100.png/ff4444/ffffff", "rating": 22 },
{ "id": 377, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/165x100.png/dddddd/000000", "rating": 52 },
{ "id": 378, "Name": "Physician Therapeutics LLC", "image": "http://dummyimage.com/242x100.png/ff4444/ffffff", "rating": 54 },
{ "id": 379, "Name": "Jubilant HollisterStier LLC", "image": "http://dummyimage.com/246x100.png/dddddd/000000", "rating": 11 },
{ "id": 380, "Name": "Apotheca Company", "image": "http://dummyimage.com/213x100.png/cc0000/ffffff", "rating": 57 },
{ "id": 381, "Name": "Macoven Pharmaceuticals", "image": "http://dummyimage.com/239x100.png/ff4444/ffffff", "rating": 44 },
{ "id": 382, "Name": "H.J. Harkins Company, Inc.", "image": "http://dummyimage.com/245x100.png/ff4444/ffffff", "rating": 18 },
{ "id": 383, "Name": "Cantrell Drug Company", "image": "http://dummyimage.com/123x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 384, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/201x100.png/ff4444/ffffff", "rating": 50 },
{ "id": 385, "Name": "Ventura Corporation (San Juan, P.R)", "image": "http://dummyimage.com/226x100.png/5fa2dd/ffffff", "rating": 30 },
{ "id": 386, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/157x100.png/5fa2dd/ffffff", "rating": 18 },
{ "id": 387, "Name": "STAT Rx USA LLC", "image": "http://dummyimage.com/215x100.png/dddddd/000000", "rating": 56 },
{ "id": 388, "Name": "Stephen L. LaFrance Pharmacy, Inc.", "image": "http://dummyimage.com/205x100.png/5fa2dd/ffffff", "rating": 35 },
{ "id": 389, "Name": "Winthrop U.S.", "image": "http://dummyimage.com/225x100.png/cc0000/ffffff", "rating": 80 },
{ "id": 390, "Name": "Sandoz Inc", "image": "http://dummyimage.com/174x100.png/cc0000/ffffff", "rating": 21 },
{ "id": 391, "Name": "Cardinal Health", "image": "http://dummyimage.com/236x100.png/ff4444/ffffff", "rating": 37 },
{ "id": 392, "Name": "WG Critical Care, LLC", "image": "http://dummyimage.com/196x100.png/cc0000/ffffff", "rating": 66 },
{ "id": 393, "Name": "CVS PHARMACY", "image": "http://dummyimage.com/215x100.png/5fa2dd/ffffff", "rating": 33 },
{ "id": 394, "Name": "Micro Labs Limited", "image": "http://dummyimage.com/117x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 395, "Name": "Cardinal Health", "image": "http://dummyimage.com/197x100.png/dddddd/000000", "rating": 74 },
{ "id": 396, "Name": "Cardinal Health", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 90 },
{ "id": 397, "Name": "L Perrigo Company", "image": "http://dummyimage.com/218x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 398, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/115x100.png/ff4444/ffffff", "rating": 10 },
{ "id": 399, "Name": "Sam's West Inc", "image": "http://dummyimage.com/106x100.png/dddddd/000000", "rating": 46 },
{ "id": 400, "Name": "Topco Associates LLC", "image": "http://dummyimage.com/206x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 401, "Name": "Aidarex Pharmaceuticals LLC", "image": "http://dummyimage.com/163x100.png/dddddd/000000", "rating": 72 },
{ "id": 402, "Name": "Hospira Worldwide, Inc.", "image": "http://dummyimage.com/194x100.png/cc0000/ffffff", "rating": 25 },
{ "id": 403, "Name": "West-ward Pharmaceutical Corp", "image": "http://dummyimage.com/238x100.png/dddddd/000000", "rating": 73 },
{ "id": 404, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/159x100.png/5fa2dd/ffffff", "rating": 15 },
{ "id": 405, "Name": "Ventura Corporation Ltd.", "image": "http://dummyimage.com/157x100.png/ff4444/ffffff", "rating": 14 },
{ "id": 406, "Name": "Uriel Pharmacy Inc.", "image": "http://dummyimage.com/167x100.png/ff4444/ffffff", "rating": 77 },
{ "id": 407, "Name": "AMERICAN CHEMICAL AND SANITARY SUPPLY INC", "image": "http://dummyimage.com/175x100.png/5fa2dd/ffffff", "rating": 69 },
{ "id": 408, "Name": "ZURICH MEDICAL LABS, LLC", "image": "http://dummyimage.com/154x100.png/ff4444/ffffff", "rating": 91 },
{ "id": 409, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/186x100.png/5fa2dd/ffffff", "rating": 1 },
{ "id": 410, "Name": "Uriel Pharmacy Inc", "image": "http://dummyimage.com/157x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 411, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/116x100.png/dddddd/000000", "rating": 16 },
{ "id": 412, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/234x100.png/ff4444/ffffff", "rating": 52 },
{ "id": 413, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/234x100.png/5fa2dd/ffffff", "rating": 91 },
{ "id": 414, "Name": "Apotheca Company", "image": "http://dummyimage.com/168x100.png/5fa2dd/ffffff", "rating": 53 },
{ "id": 415, "Name": "Meijer Distribution, Inc.", "image": "http://dummyimage.com/182x100.png/cc0000/ffffff", "rating": 49 },
{ "id": 416, "Name": "L. Perrigo Company", "image": "http://dummyimage.com/143x100.png/ff4444/ffffff", "rating": 62 },
{ "id": 417, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/105x100.png/cc0000/ffffff", "rating": 21 },
{ "id": 418, "Name": "Major Pharmaceuticals", "image": "http://dummyimage.com/117x100.png/5fa2dd/ffffff", "rating": 32 },
{ "id": 419, "Name": "NARS COSMETICS", "image": "http://dummyimage.com/191x100.png/dddddd/000000", "rating": 15 },
{ "id": 420, "Name": "Amerisource Bergen", "image": "http://dummyimage.com/136x100.png/ff4444/ffffff", "rating": 74 },
{ "id": 421, "Name": "Teva Parenteral Medicines, Inc.", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 29 },
{ "id": 422, "Name": "Washington Homeopathic Products", "image": "http://dummyimage.com/161x100.png/ff4444/ffffff", "rating": 60 },
{ "id": 423, "Name": "Chattem, Inc.", "image": "http://dummyimage.com/186x100.png/dddddd/000000", "rating": 60 },
{ "id": 424, "Name": "Melaleuca, Inc.", "image": "http://dummyimage.com/208x100.png/dddddd/000000", "rating": 86 },
{ "id": 425, "Name": "Linde Eckstein GmbH + Co. KG", "image": "http://dummyimage.com/185x100.png/5fa2dd/ffffff", "rating": 39 },
{ "id": 426, "Name": "Roxane Laboratories, Inc", "image": "http://dummyimage.com/212x100.png/cc0000/ffffff", "rating": 36 },
{ "id": 427, "Name": "Corepharma LLC.", "image": "http://dummyimage.com/188x100.png/dddddd/000000", "rating": 59 },
{ "id": 428, "Name": "Syntrion GmbH", "image": "http://dummyimage.com/179x100.png/5fa2dd/ffffff", "rating": 6 },
{ "id": 429, "Name": "Fresenius Kabi USA, LLC", "image": "http://dummyimage.com/165x100.png/5fa2dd/ffffff", "rating": 27 },
{ "id": 430, "Name": "Reckitt Benckiser LLC", "image": "http://dummyimage.com/155x100.png/cc0000/ffffff", "rating": 24 },
{ "id": 431, "Name": "Merle Norman Cosmetics, Inc.", "image": "http://dummyimage.com/129x100.png/dddddd/000000", "rating": 51 },
{ "id": 432, "Name": "Teva Parenteral Medicines, Inc.", "image": "http://dummyimage.com/192x100.png/cc0000/ffffff", "rating": 35 },
{ "id": 433, "Name": "Nan Mei Pharmaceutical Co., Ltd.", "image": "http://dummyimage.com/223x100.png/cc0000/ffffff", "rating": 29 },
{ "id": 434, "Name": "DAVA Pharmaceuticals Inc", "image": "http://dummyimage.com/225x100.png/cc0000/ffffff", "rating": 59 },
{ "id": 435, "Name": "Glenmark Generics Inc., USA", "image": "http://dummyimage.com/124x100.png/dddddd/000000", "rating": 1 },
{ "id": 436, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/124x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 437, "Name": "VVF Illinois Services LLC", "image": "http://dummyimage.com/162x100.png/dddddd/000000", "rating": 78 },
{ "id": 438, "Name": "Sandoz Inc", "image": "http://dummyimage.com/146x100.png/cc0000/ffffff", "rating": 96 },
{ "id": 439, "Name": "Elizabeth Arden, Inc", "image": "http://dummyimage.com/241x100.png/cc0000/ffffff", "rating": 58 },
{ "id": 440, "Name": "APP Pharmaceuticals, LLC", "image": "http://dummyimage.com/173x100.png/5fa2dd/ffffff", "rating": 49 },
{ "id": 441, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/233x100.png/dddddd/000000", "rating": 70 },
{ "id": 442, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/238x100.png/cc0000/ffffff", "rating": 56 },
{ "id": 443, "Name": "AMOREPACIFIC", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 68 },
{ "id": 444, "Name": "Canberra Corporation", "image": "http://dummyimage.com/112x100.png/cc0000/ffffff", "rating": 5 },
{ "id": 445, "Name": "AbbVie Inc.", "image": "http://dummyimage.com/165x100.png/dddddd/000000", "rating": 88 },
{ "id": 446, "Name": "Proficient Rx LP", "image": "http://dummyimage.com/240x100.png/cc0000/ffffff", "rating": 61 },
{ "id": 447, "Name": "Nelson Bach USA, Ltd.", "image": "http://dummyimage.com/219x100.png/dddddd/000000", "rating": 59 },
{ "id": 448, "Name": "McKesson Packaging Services a business unit of McKesson Corporation", "image": "http://dummyimage.com/139x100.png/cc0000/ffffff", "rating": 19 },
{ "id": 449, "Name": "NATURAL ESSENTIALS, INC.", "image": "http://dummyimage.com/241x100.png/5fa2dd/ffffff", "rating": 25 },
{ "id": 450, "Name": "Richmond Division of Wyeth", "image": "http://dummyimage.com/217x100.png/cc0000/ffffff", "rating": 15 },
{ "id": 451, "Name": "Jakemans (Confectioners) Ltd.", "image": "http://dummyimage.com/244x100.png/5fa2dd/ffffff", "rating": 36 },
{ "id": 452, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/216x100.png/cc0000/ffffff", "rating": 37 },
{ "id": 453, "Name": "Fresenius Kabi USA, LLC", "image": "http://dummyimage.com/243x100.png/dddddd/000000", "rating": 4 },
{ "id": 454, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/128x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 455, "Name": "HANLIM PHARM. CO., LTD.", "image": "http://dummyimage.com/205x100.png/5fa2dd/ffffff", "rating": 82 },
{ "id": 456, "Name": "T Gone Remedies", "image": "http://dummyimage.com/199x100.png/ff4444/ffffff", "rating": 94 },
{ "id": 457, "Name": "Lake Erie Medical DBA Quality Care Products LLC", "image": "http://dummyimage.com/153x100.png/dddddd/000000", "rating": 61 },
{ "id": 458, "Name": "Taro Pharmaceuticals U.S.A., Inc.", "image": "http://dummyimage.com/247x100.png/cc0000/ffffff", "rating": 65 },
{ "id": 459, "Name": "Teva Parenteral Medicines, Inc.", "image": "http://dummyimage.com/139x100.png/5fa2dd/ffffff", "rating": 61 },
{ "id": 460, "Name": "Deseret Biologicals", "image": "http://dummyimage.com/119x100.png/cc0000/ffffff", "rating": 65 },
{ "id": 461, "Name": "Legacy Pharmaceutical Packaging", "image": "http://dummyimage.com/169x100.png/ff4444/ffffff", "rating": 59 },
{ "id": 462, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/164x100.png/dddddd/000000", "rating": 53 },
{ "id": 463, "Name": "Apotex Corp.", "image": "http://dummyimage.com/111x100.png/ff4444/ffffff", "rating": 87 },
{ "id": 464, "Name": "The Wellness Center", "image": "http://dummyimage.com/128x100.png/cc0000/ffffff", "rating": 11 },
{ "id": 465, "Name": "Rising Pharmaceuticals Inc", "image": "http://dummyimage.com/117x100.png/5fa2dd/ffffff", "rating": 28 },
{ "id": 466, "Name": "Clinical Solutions Wholesale", "image": "http://dummyimage.com/125x100.png/dddddd/000000", "rating": 88 },
{ "id": 467, "Name": "RITE AID CORPORATION", "image": "http://dummyimage.com/109x100.png/dddddd/000000", "rating": 94 },
{ "id": 468, "Name": "PharmaDerm a division of Fougera Pharmaceuticals Inc.", "image": "http://dummyimage.com/238x100.png/5fa2dd/ffffff", "rating": 96 },
{ "id": 469, "Name": "Supervalu Inc", "image": "http://dummyimage.com/206x100.png/cc0000/ffffff", "rating": 20 },
{ "id": 470, "Name": "NCS HealthCare of KY, Inc dba Vangard Labs", "image": "http://dummyimage.com/213x100.png/ff4444/ffffff", "rating": 99 },
{ "id": 471, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/199x100.png/dddddd/000000", "rating": 94 },
{ "id": 472, "Name": "Eli Lilly and Company", "image": "http://dummyimage.com/115x100.png/ff4444/ffffff", "rating": 17 },
{ "id": 473, "Name": "Procter & Gamble Manufacturing Co.", "image": "http://dummyimage.com/206x100.png/ff4444/ffffff", "rating": 51 },
{ "id": 474, "Name": "Rebel Distributors Corp.", "image": "http://dummyimage.com/187x100.png/cc0000/ffffff", "rating": 70 },
{ "id": 475, "Name": "Dr. Reddy's Laboratories Limited", "image": "http://dummyimage.com/120x100.png/5fa2dd/ffffff", "rating": 98 },
{ "id": 476, "Name": "Cadila Healthcare Limited", "image": "http://dummyimage.com/154x100.png/dddddd/000000", "rating": 52 },
{ "id": 477, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/158x100.png/ff4444/ffffff", "rating": 21 },
{ "id": 478, "Name": "Dolgencorp, Inc. (DOLLAR GENERAL & REXALL)", "image": "http://dummyimage.com/180x100.png/cc0000/ffffff", "rating": 31 },
{ "id": 479, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/183x100.png/ff4444/ffffff", "rating": 36 },
{ "id": 480, "Name": "Shopko", "image": "http://dummyimage.com/220x100.png/dddddd/000000", "rating": 9 },
{ "id": 481, "Name": "The Mentholatum Company", "image": "http://dummyimage.com/150x100.png/dddddd/000000", "rating": 1 },
{ "id": 482, "Name": "Mary Kay Inc.", "image": "http://dummyimage.com/178x100.png/ff4444/ffffff", "rating": 48 },
{ "id": 483, "Name": "Cardinal Health", "image": "http://dummyimage.com/167x100.png/ff4444/ffffff", "rating": 32 },
{ "id": 484, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/148x100.png/dddddd/000000", "rating": 59 },
{ "id": 485, "Name": "NPS Pharmaceuticals", "image": "http://dummyimage.com/174x100.png/ff4444/ffffff", "rating": 90 },
{ "id": 486, "Name": "Cardinal Health", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 10 },
{ "id": 487, "Name": "Carver Korea Co.,Ltd", "image": "http://dummyimage.com/243x100.png/dddddd/000000", "rating": 11 },
{ "id": 488, "Name": "Johnson & Johnson Consumer Products Company, Division of Johnson & Johnson Consumer Companies, Inc.", "image": "http://dummyimage.com/153x100.png/ff4444/ffffff", "rating": 61 },
{ "id": 489, "Name": "Allergy Laboratories, Inc.", "image": "http://dummyimage.com/155x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 490, "Name": "Uriel Pharmacy Inc.", "image": "http://dummyimage.com/120x100.png/cc0000/ffffff", "rating": 44 },
{ "id": 491, "Name": "GlaxoSmithKline Consumer Heathcare LP", "image": "http://dummyimage.com/154x100.png/ff4444/ffffff", "rating": 98 },
{ "id": 492, "Name": "American Health Packaging", "image": "http://dummyimage.com/159x100.png/5fa2dd/ffffff", "rating": 16 },
{ "id": 493, "Name": "Aphena Pharma Solutions - Tennessee, Inc.", "image": "http://dummyimage.com/228x100.png/cc0000/ffffff", "rating": 89 },
{ "id": 494, "Name": "Energique, Inc.", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 39 },
{ "id": 495, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/224x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 496, "Name": "Lake Erie Medical & Surgical Supply DBA Quality Care Products LLC", "image": "http://dummyimage.com/139x100.png/5fa2dd/ffffff", "rating": 75 },
{ "id": 497, "Name": "Bioelements, Inc.", "image": "http://dummyimage.com/172x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 498, "Name": "Professional Disposables International, Inc.", "image": "http://dummyimage.com/134x100.png/ff4444/ffffff", "rating": 88 },
{ "id": 499, "Name": "Roerig", "image": "http://dummyimage.com/106x100.png/ff4444/ffffff", "rating": 90 },
{ "id": 500, "Name": "JUBILANT CADISTA PHARMACEUTICALS, INC.", "image": "http://dummyimage.com/175x100.png/cc0000/ffffff", "rating": 77 },
{ "id": 501, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/229x100.png/dddddd/000000", "rating": 82 },
{ "id": 502, "Name": "SAFEWAY INC.", "image": "http://dummyimage.com/140x100.png/cc0000/ffffff", "rating": 11 },
{ "id": 503, "Name": "Endo Pharmaceuticals Inc. DBA Endo Generic Products", "image": "http://dummyimage.com/193x100.png/ff4444/ffffff", "rating": 19 },
{ "id": 504, "Name": "EQUATE (Walmart Stores, Inc.)", "image": "http://dummyimage.com/160x100.png/dddddd/000000", "rating": 63 },
{ "id": 505, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/148x100.png/ff4444/ffffff", "rating": 34 },
{ "id": 506, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 73 },
{ "id": 507, "Name": "Greenstone LLC", "image": "http://dummyimage.com/190x100.png/cc0000/ffffff", "rating": 75 },
{ "id": 508, "Name": "Sandoz Inc", "image": "http://dummyimage.com/244x100.png/cc0000/ffffff", "rating": 25 },
{ "id": 509, "Name": "Gambro Renal Products", "image": "http://dummyimage.com/166x100.png/cc0000/ffffff", "rating": 22 },
{ "id": 510, "Name": "King Bio Inc.", "image": "http://dummyimage.com/158x100.png/5fa2dd/ffffff", "rating": 4 },
{ "id": 511, "Name": "McKesson", "image": "http://dummyimage.com/185x100.png/cc0000/ffffff", "rating": 26 },
{ "id": 512, "Name": "Tarte, Inc", "image": "http://dummyimage.com/117x100.png/cc0000/ffffff", "rating": 95 },
{ "id": 513, "Name": "STAT Rx USA LLC", "image": "http://dummyimage.com/144x100.png/5fa2dd/ffffff", "rating": 32 },
{ "id": 514, "Name": "Dynarex Corporation", "image": "http://dummyimage.com/137x100.png/cc0000/ffffff", "rating": 50 },
{ "id": 515, "Name": "American Sales Company", "image": "http://dummyimage.com/222x100.png/cc0000/ffffff", "rating": 16 },
{ "id": 516, "Name": "Lupin Pharmaceuticals, Inc.", "image": "http://dummyimage.com/151x100.png/dddddd/000000", "rating": 17 },
{ "id": 517, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/192x100.png/dddddd/000000", "rating": 39 },
{ "id": 518, "Name": "Unit Dose Services", "image": "http://dummyimage.com/204x100.png/5fa2dd/ffffff", "rating": 16 },
{ "id": 519, "Name": "MedVantx, Inc.", "image": "http://dummyimage.com/241x100.png/cc0000/ffffff", "rating": 57 },
{ "id": 520, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/133x100.png/dddddd/000000", "rating": 38 },
{ "id": 521, "Name": "Jubilant HollisterStier LLC", "image": "http://dummyimage.com/153x100.png/dddddd/000000", "rating": 68 },
{ "id": 522, "Name": "Preferred Pharmaceuticals, Inc.", "image": "http://dummyimage.com/198x100.png/ff4444/ffffff", "rating": 3 },
{ "id": 523, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/213x100.png/dddddd/000000", "rating": 81 },
{ "id": 524, "Name": "DZA Brands LLC", "image": "http://dummyimage.com/164x100.png/cc0000/ffffff", "rating": 72 },
{ "id": 525, "Name": "Dr. Fresh, Inc.", "image": "http://dummyimage.com/177x100.png/cc0000/ffffff", "rating": 22 },
{ "id": 526, "Name": "Pharmacia and Upjohn Company", "image": "http://dummyimage.com/182x100.png/5fa2dd/ffffff", "rating": 76 },
{ "id": 527, "Name": "Mckesson (Health Mart)", "image": "http://dummyimage.com/200x100.png/cc0000/ffffff", "rating": 4 },
{ "id": 528, "Name": "APP Pharmaceuticals, LLC", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 12 },
{ "id": 529, "Name": "Cardinal Health", "image": "http://dummyimage.com/102x100.png/ff4444/ffffff", "rating": 8 },
{ "id": 530, "Name": "A-S Medication Solutions LLC", "image": "http://dummyimage.com/168x100.png/cc0000/ffffff", "rating": 89 },
{ "id": 531, "Name": "Rite Aid", "image": "http://dummyimage.com/132x100.png/dddddd/000000", "rating": 36 },
{ "id": 532, "Name": "Cardinal Health", "image": "http://dummyimage.com/125x100.png/dddddd/000000", "rating": 76 },
{ "id": 533, "Name": "0to7 Inc", "image": "http://dummyimage.com/166x100.png/5fa2dd/ffffff", "rating": 83 },
{ "id": 534, "Name": "International Nature Nutraceuticals", "image": "http://dummyimage.com/202x100.png/dddddd/000000", "rating": 35 },
{ "id": 535, "Name": "Par Pharmaceutical, Inc.", "image": "http://dummyimage.com/127x100.png/ff4444/ffffff", "rating": 93 },
{ "id": 536, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/245x100.png/cc0000/ffffff", "rating": 31 },
{ "id": 537, "Name": "Geiss, Destin & Dunn, Inc.", "image": "http://dummyimage.com/165x100.png/ff4444/ffffff", "rating": 27 },
{ "id": 538, "Name": "Rugby Laboratories Inc.", "image": "http://dummyimage.com/161x100.png/dddddd/000000", "rating": 77 },
{ "id": 539, "Name": "AMERICAN SALES COMPANY", "image": "http://dummyimage.com/137x100.png/cc0000/ffffff", "rating": 88 },
{ "id": 540, "Name": "Jubilant HollisterStier LLC", "image": "http://dummyimage.com/170x100.png/dddddd/000000", "rating": 20 },
{ "id": 541, "Name": "Colgate-Palmolive Company", "image": "http://dummyimage.com/199x100.png/cc0000/ffffff", "rating": 22 },
{ "id": 542, "Name": "American Health Packaging", "image": "http://dummyimage.com/161x100.png/dddddd/000000", "rating": 99 },
{ "id": 543, "Name": "Ventura Corporation LTD", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 90 },
{ "id": 544, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/222x100.png/cc0000/ffffff", "rating": 95 },
{ "id": 545, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/202x100.png/cc0000/ffffff", "rating": 36 },
{ "id": 546, "Name": "Golden State Medical Supply, Inc.", "image": "http://dummyimage.com/119x100.png/cc0000/ffffff", "rating": 87 },
{ "id": 547, "Name": "MICRO CONNECTION ENTERPRISES INC", "image": "http://dummyimage.com/214x100.png/ff4444/ffffff", "rating": 73 },
{ "id": 548, "Name": "Walgreen Company", "image": "http://dummyimage.com/214x100.png/cc0000/ffffff", "rating": 37 },
{ "id": 549, "Name": "Dolgencorp Inc", "image": "http://dummyimage.com/202x100.png/dddddd/000000", "rating": 85 },
{ "id": 550, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/134x100.png/ff4444/ffffff", "rating": 52 },
{ "id": 551, "Name": "Actavis Pharma, Inc.", "image": "http://dummyimage.com/218x100.png/dddddd/000000", "rating": 87 },
{ "id": 552, "Name": "Western Family Food, Inc.", "image": "http://dummyimage.com/130x100.png/cc0000/ffffff", "rating": 48 },
{ "id": 553, "Name": "H.J. Harkins Company, Inc.", "image": "http://dummyimage.com/202x100.png/dddddd/000000", "rating": 13 },
{ "id": 554, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/211x100.png/ff4444/ffffff", "rating": 68 },
{ "id": 555, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/209x100.png/cc0000/ffffff", "rating": 29 },
{ "id": 556, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/246x100.png/cc0000/ffffff", "rating": 14 },
{ "id": 557, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/197x100.png/cc0000/ffffff", "rating": 94 },
{ "id": 558, "Name": "Aidarex Pharmaceuticals LLC", "image": "http://dummyimage.com/109x100.png/dddddd/000000", "rating": 99 },
{ "id": 559, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/239x100.png/cc0000/ffffff", "rating": 4 },
{ "id": 560, "Name": "Par Pharmaceutical Inc", "image": "http://dummyimage.com/110x100.png/dddddd/000000", "rating": 58 },
{ "id": 561, "Name": "Total Suncare LLC", "image": "http://dummyimage.com/217x100.png/5fa2dd/ffffff", "rating": 20 },
{ "id": 562, "Name": "Dispensing Solutions, Inc.", "image": "http://dummyimage.com/155x100.png/5fa2dd/ffffff", "rating": 61 },
{ "id": 563, "Name": "Safeway Inc.", "image": "http://dummyimage.com/244x100.png/ff4444/ffffff", "rating": 48 },
{ "id": 564, "Name": "United Exchange Corp", "image": "http://dummyimage.com/250x100.png/ff4444/ffffff", "rating": 95 },
{ "id": 565, "Name": "Bare Escentuals Beauty Inc.", "image": "http://dummyimage.com/139x100.png/ff4444/ffffff", "rating": 78 },
{ "id": 566, "Name": "The Clorox Company", "image": "http://dummyimage.com/184x100.png/ff4444/ffffff", "rating": 3 },
{ "id": 567, "Name": "Medline Industries", "image": "http://dummyimage.com/231x100.png/5fa2dd/ffffff", "rating": 77 },
{ "id": 568, "Name": "Ventura Corporation (San Juan, P.R)", "image": "http://dummyimage.com/118x100.png/5fa2dd/ffffff", "rating": 98 },
{ "id": 569, "Name": "Laboratoires Clarins S.A.", "image": "http://dummyimage.com/224x100.png/5fa2dd/ffffff", "rating": 33 },
{ "id": 570, "Name": "Cardinal Health", "image": "http://dummyimage.com/147x100.png/5fa2dd/ffffff", "rating": 17 },
{ "id": 571, "Name": "PureTek Corporation", "image": "http://dummyimage.com/100x100.png/ff4444/ffffff", "rating": 1 },
{ "id": 572, "Name": "Kmart Corporation", "image": "http://dummyimage.com/123x100.png/5fa2dd/ffffff", "rating": 97 },
{ "id": 573, "Name": "Fresenius Medical Care North America", "image": "http://dummyimage.com/142x100.png/5fa2dd/ffffff", "rating": 37 },
{ "id": 574, "Name": "Reckitt Benckiser, Inc.", "image": "http://dummyimage.com/236x100.png/dddddd/000000", "rating": 87 },
{ "id": 575, "Name": "Apo-Pharma USA, Inc", "image": "http://dummyimage.com/226x100.png/cc0000/ffffff", "rating": 27 },
{ "id": 576, "Name": "Cintas First Aid & Safety", "image": "http://dummyimage.com/162x100.png/dddddd/000000", "rating": 88 },
{ "id": 577, "Name": "Lannett Company, Inc.", "image": "http://dummyimage.com/114x100.png/cc0000/ffffff", "rating": 3 },
{ "id": 578, "Name": "Apotex Corp.", "image": "http://dummyimage.com/139x100.png/cc0000/ffffff", "rating": 65 },
{ "id": 579, "Name": "Humanwell PuraCap Pharmaceutical (Wuhan), Ltd.", "image": "http://dummyimage.com/191x100.png/dddddd/000000", "rating": 80 },
{ "id": 580, "Name": "WOCKHARDT USA LLC", "image": "http://dummyimage.com/141x100.png/dddddd/000000", "rating": 62 },
{ "id": 581, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/110x100.png/ff4444/ffffff", "rating": 77 },
{ "id": 582, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/135x100.png/dddddd/000000", "rating": 99 },
{ "id": 583, "Name": "Rugby Laboratories", "image": "http://dummyimage.com/194x100.png/dddddd/000000", "rating": 59 },
{ "id": 584, "Name": "Heritage Pharmaceuticals Inc.", "image": "http://dummyimage.com/184x100.png/dddddd/000000", "rating": 42 },
{ "id": 585, "Name": "Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/101x100.png/ff4444/ffffff", "rating": 32 },
{ "id": 586, "Name": "Richmond Division of Wyeth", "image": "http://dummyimage.com/173x100.png/cc0000/ffffff", "rating": 42 },
{ "id": 587, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/119x100.png/5fa2dd/ffffff", "rating": 79 },
{ "id": 588, "Name": "HyVee Inc", "image": "http://dummyimage.com/157x100.png/dddddd/000000", "rating": 67 },
{ "id": 589, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/197x100.png/5fa2dd/ffffff", "rating": 96 },
{ "id": 590, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/180x100.png/dddddd/000000", "rating": 33 },
{ "id": 591, "Name": "Select Brand", "image": "http://dummyimage.com/194x100.png/5fa2dd/ffffff", "rating": 38 },
{ "id": 592, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 8 },
{ "id": 593, "Name": "Salimex, S.A.", "image": "http://dummyimage.com/117x100.png/cc0000/ffffff", "rating": 84 },
{ "id": 594, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/128x100.png/ff4444/ffffff", "rating": 55 },
{ "id": 595, "Name": "Medicis Pharmaceutical Corp.", "image": "http://dummyimage.com/221x100.png/cc0000/ffffff", "rating": 2 },
{ "id": 596, "Name": "NARTEX LABORATORIOS HOMEOPATICOS SA DE CV", "image": "http://dummyimage.com/218x100.png/ff4444/ffffff", "rating": 80 },
{ "id": 597, "Name": "Bausch & Lomb Incorporated", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 13 },
{ "id": 598, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/107x100.png/cc0000/ffffff", "rating": 18 },
{ "id": 599, "Name": "SJ Creations, Inc.", "image": "http://dummyimage.com/169x100.png/ff4444/ffffff", "rating": 93 },
{ "id": 600, "Name": "WOONSOCKET PRESCRIPTION CENTER,INCORPORATED", "image": "http://dummyimage.com/200x100.png/ff4444/ffffff", "rating": 4 },
{ "id": 601, "Name": "Proficient Rx LP", "image": "http://dummyimage.com/150x100.png/cc0000/ffffff", "rating": 20 },
{ "id": 602, "Name": "Ken Mable", "image": "http://dummyimage.com/199x100.png/dddddd/000000", "rating": 70 },
{ "id": 603, "Name": "Patterson Dental", "image": "http://dummyimage.com/162x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 604, "Name": "Western Family Foods Inc", "image": "http://dummyimage.com/101x100.png/dddddd/000000", "rating": 33 },
{ "id": 605, "Name": "HOMEOLAB USA INC.", "image": "http://dummyimage.com/134x100.png/dddddd/000000", "rating": 97 },
{ "id": 606, "Name": "Parke-Davis Div of Pfizer Inc", "image": "http://dummyimage.com/135x100.png/5fa2dd/ffffff", "rating": 6 },
{ "id": 607, "Name": "WALGREEN CO", "image": "http://dummyimage.com/117x100.png/cc0000/ffffff", "rating": 51 },
{ "id": 608, "Name": "Cardinal Health", "image": "http://dummyimage.com/242x100.png/cc0000/ffffff", "rating": 15 },
{ "id": 609, "Name": "Pharmicell Co., Ltd.", "image": "http://dummyimage.com/194x100.png/cc0000/ffffff", "rating": 17 },
{ "id": 610, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/107x100.png/dddddd/000000", "rating": 64 },
{ "id": 611, "Name": "Cardinal Health", "image": "http://dummyimage.com/122x100.png/cc0000/ffffff", "rating": 89 },
{ "id": 612, "Name": "Dr. Reddy's Laboratories Limited", "image": "http://dummyimage.com/139x100.png/ff4444/ffffff", "rating": 53 },
{ "id": 613, "Name": "Chantecaille Beaute Inc", "image": "http://dummyimage.com/219x100.png/ff4444/ffffff", "rating": 54 },
{ "id": 614, "Name": "RedPharm Drug Inc.", "image": "http://dummyimage.com/222x100.png/ff4444/ffffff", "rating": 93 },
{ "id": 615, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/213x100.png/5fa2dd/ffffff", "rating": 30 },
{ "id": 616, "Name": "GE Healthcare Inc.", "image": "http://dummyimage.com/167x100.png/cc0000/ffffff", "rating": 25 },
{ "id": 617, "Name": "Pedinol Pharmacal, Inc.", "image": "http://dummyimage.com/133x100.png/cc0000/ffffff", "rating": 67 },
{ "id": 618, "Name": "Ventura Corporation LTD.", "image": "http://dummyimage.com/203x100.png/dddddd/000000", "rating": 82 },
{ "id": 619, "Name": "Tri-Coastal Design Company Inc.", "image": "http://dummyimage.com/121x100.png/5fa2dd/ffffff", "rating": 30 },
{ "id": 620, "Name": "Laser Pharmaceuticals, LLC", "image": "http://dummyimage.com/121x100.png/ff4444/ffffff", "rating": 82 },
{ "id": 621, "Name": "Paddock Laboratories, LLC", "image": "http://dummyimage.com/248x100.png/dddddd/000000", "rating": 88 },
{ "id": 622, "Name": "Meijer Distribution Inc", "image": "http://dummyimage.com/180x100.png/cc0000/ffffff", "rating": 60 },
{ "id": 623, "Name": "Precision Dose Inc.", "image": "http://dummyimage.com/248x100.png/5fa2dd/ffffff", "rating": 50 },
{ "id": 624, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/148x100.png/cc0000/ffffff", "rating": 66 },
{ "id": 625, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/132x100.png/5fa2dd/ffffff", "rating": 76 },
{ "id": 626, "Name": "BJWC", "image": "http://dummyimage.com/181x100.png/cc0000/ffffff", "rating": 80 },
{ "id": 627, "Name": "Aphena Pharma Solutions - Tennessee, LLC", "image": "http://dummyimage.com/128x100.png/ff4444/ffffff", "rating": 57 },
{ "id": 628, "Name": "Pharmacia and Upjohn Company", "image": "http://dummyimage.com/144x100.png/5fa2dd/ffffff", "rating": 44 },
{ "id": 629, "Name": "WOONSOCKET PRESCRIPTION CENTER,INCORPORATED", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 65 },
{ "id": 630, "Name": "Unit Dose Services", "image": "http://dummyimage.com/226x100.png/ff4444/ffffff", "rating": 65 },
{ "id": 631, "Name": "American Health Packaging", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 96 },
{ "id": 632, "Name": "Aurolife Pharma, LLC", "image": "http://dummyimage.com/162x100.png/5fa2dd/ffffff", "rating": 37 },
{ "id": 633, "Name": "A-S Medication Solutions LLC", "image": "http://dummyimage.com/246x100.png/cc0000/ffffff", "rating": 62 },
{ "id": 634, "Name": "Wakefern Food Corporation", "image": "http://dummyimage.com/193x100.png/cc0000/ffffff", "rating": 44 },
{ "id": 635, "Name": "Ecolab Inc.", "image": "http://dummyimage.com/184x100.png/ff4444/ffffff", "rating": 91 },
{ "id": 636, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/244x100.png/5fa2dd/ffffff", "rating": 35 },
{ "id": 637, "Name": "American Health Packaging", "image": "http://dummyimage.com/146x100.png/5fa2dd/ffffff", "rating": 41 },
{ "id": 638, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/105x100.png/ff4444/ffffff", "rating": 54 },
{ "id": 639, "Name": "Cadila Healthcare Limited", "image": "http://dummyimage.com/218x100.png/cc0000/ffffff", "rating": 70 },
{ "id": 640, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/116x100.png/dddddd/000000", "rating": 20 },
{ "id": 641, "Name": "Clinical Solutions Wholesale", "image": "http://dummyimage.com/116x100.png/dddddd/000000", "rating": 44 },
{ "id": 642, "Name": "LG Household and Healthcare, Inc.", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 77 },
{ "id": 643, "Name": "Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/146x100.png/dddddd/000000", "rating": 33 },
{ "id": 644, "Name": "Select Brand", "image": "http://dummyimage.com/246x100.png/5fa2dd/ffffff", "rating": 57 },
{ "id": 645, "Name": "Coloplast Manufacturing US, LLC", "image": "http://dummyimage.com/194x100.png/dddddd/000000", "rating": 86 },
{ "id": 646, "Name": "nizatidine", "image": "http://dummyimage.com/160x100.png/5fa2dd/ffffff", "rating": 32 },
{ "id": 647, "Name": "Wockhardt USA LLC.", "image": "http://dummyimage.com/112x100.png/5fa2dd/ffffff", "rating": 60 },
{ "id": 648, "Name": "Western Family Foods Inc", "image": "http://dummyimage.com/210x100.png/dddddd/000000", "rating": 17 },
{ "id": 649, "Name": "Aurobindo Pharma Limited", "image": "http://dummyimage.com/190x100.png/5fa2dd/ffffff", "rating": 21 },
{ "id": 650, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/218x100.png/dddddd/000000", "rating": 62 },
{ "id": 651, "Name": "White Manufacturing Inc. DBA Micro-West", "image": "http://dummyimage.com/109x100.png/cc0000/ffffff", "rating": 1 },
{ "id": 652, "Name": "Teva Select Brands", "image": "http://dummyimage.com/207x100.png/dddddd/000000", "rating": 51 },
{ "id": 653, "Name": "Peaceful Mountain, Inc.", "image": "http://dummyimage.com/237x100.png/cc0000/ffffff", "rating": 37 },
{ "id": 654, "Name": "Caregen Co., Ltd.", "image": "http://dummyimage.com/224x100.png/dddddd/000000", "rating": 37 },
{ "id": 655, "Name": "Pfizer Laboratories Div Pfizer Inc.", "image": "http://dummyimage.com/175x100.png/ff4444/ffffff", "rating": 75 },
{ "id": 656, "Name": "Premier Value", "image": "http://dummyimage.com/200x100.png/dddddd/000000", "rating": 97 },
{ "id": 657, "Name": "Lake Erie Medical DBA Quality Care Products LLC", "image": "http://dummyimage.com/220x100.png/5fa2dd/ffffff", "rating": 11 },
{ "id": 658, "Name": "Reckitt Benckiser LLC", "image": "http://dummyimage.com/229x100.png/dddddd/000000", "rating": 41 },
{ "id": 659, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/228x100.png/ff4444/ffffff", "rating": 41 },
{ "id": 660, "Name": "Energique, Inc.", "image": "http://dummyimage.com/124x100.png/cc0000/ffffff", "rating": 49 },
{ "id": 661, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/247x100.png/5fa2dd/ffffff", "rating": 12 },
{ "id": 662, "Name": "SJ Creations, Inc.", "image": "http://dummyimage.com/135x100.png/ff4444/ffffff", "rating": 82 },
{ "id": 663, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/142x100.png/5fa2dd/ffffff", "rating": 21 },
{ "id": 664, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/101x100.png/dddddd/000000", "rating": 96 },
{ "id": 665, "Name": "Lupin Pharmaceuticals, Inc.", "image": "http://dummyimage.com/241x100.png/cc0000/ffffff", "rating": 12 },
{ "id": 666, "Name": "Ecomine Co Ltd", "image": "http://dummyimage.com/196x100.png/ff4444/ffffff", "rating": 42 },
{ "id": 667, "Name": "Eon Labs, Inc.", "image": "http://dummyimage.com/167x100.png/cc0000/ffffff", "rating": 1 },
{ "id": 668, "Name": "Native Remedies, LLC", "image": "http://dummyimage.com/209x100.png/dddddd/000000", "rating": 28 },
{ "id": 669, "Name": "Proficient Rx LP", "image": "http://dummyimage.com/139x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 670, "Name": "Preferred Pharmaceuticals, Inc.", "image": "http://dummyimage.com/208x100.png/5fa2dd/ffffff", "rating": 46 },
{ "id": 671, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/235x100.png/dddddd/000000", "rating": 65 },
{ "id": 672, "Name": "Warner Chilcott Pharmaceuticals Inc.", "image": "http://dummyimage.com/199x100.png/dddddd/000000", "rating": 58 },
{ "id": 673, "Name": "Laboratoires Clarins S.A.", "image": "http://dummyimage.com/186x100.png/dddddd/000000", "rating": 2 },
{ "id": 674, "Name": "Rugby Laboratories, Inc.", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 2 },
{ "id": 675, "Name": "ABBE Laboratories, Inc.", "image": "http://dummyimage.com/108x100.png/dddddd/000000", "rating": 56 },
{ "id": 676, "Name": "Stephen L. LaFrance Pharmacy, Inc.", "image": "http://dummyimage.com/197x100.png/cc0000/ffffff", "rating": 82 },
{ "id": 677, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/155x100.png/ff4444/ffffff", "rating": 98 },
{ "id": 678, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/121x100.png/ff4444/ffffff", "rating": 56 },
{ "id": 679, "Name": "Cardinal Health", "image": "http://dummyimage.com/119x100.png/ff4444/ffffff", "rating": 30 },
{ "id": 680, "Name": "WAL-MART STORES INC", "image": "http://dummyimage.com/132x100.png/dddddd/000000", "rating": 28 },
{ "id": 681, "Name": "Target Corporation", "image": "http://dummyimage.com/241x100.png/ff4444/ffffff", "rating": 76 },
{ "id": 682, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/113x100.png/cc0000/ffffff", "rating": 68 },
{ "id": 683, "Name": "Personal Care Products, Inc.", "image": "http://dummyimage.com/150x100.png/dddddd/000000", "rating": 90 },
{ "id": 684, "Name": "Apotheca Company", "image": "http://dummyimage.com/174x100.png/cc0000/ffffff", "rating": 5 },
{ "id": 685, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/227x100.png/5fa2dd/ffffff", "rating": 13 },
{ "id": 686, "Name": "LAURA GELLER MAKE UP INC.", "image": "http://dummyimage.com/159x100.png/ff4444/ffffff", "rating": 45 },
{ "id": 687, "Name": "Ecolab Inc.", "image": "http://dummyimage.com/101x100.png/ff4444/ffffff", "rating": 52 },
{ "id": 688, "Name": "Precision Dose Inc.", "image": "http://dummyimage.com/188x100.png/dddddd/000000", "rating": 47 },
{ "id": 689, "Name": "MICRO CONNECTION ENTERPRISES INC", "image": "http://dummyimage.com/104x100.png/dddddd/000000", "rating": 73 },
{ "id": 690, "Name": "Gemini Pharmaceuticals, Inc. dba Plus Pharmaceuticals", "image": "http://dummyimage.com/222x100.png/dddddd/000000", "rating": 53 },
{ "id": 691, "Name": "Cardinal Health", "image": "http://dummyimage.com/230x100.png/cc0000/ffffff", "rating": 96 },
{ "id": 692, "Name": "Aphena Pharma Solutions - Tennessee, LLC", "image": "http://dummyimage.com/245x100.png/ff4444/ffffff", "rating": 64 },
{ "id": 693, "Name": "Chartwell RX LLC", "image": "http://dummyimage.com/218x100.png/dddddd/000000", "rating": 99 },
{ "id": 694, "Name": "Sandoz Inc.", "image": "http://dummyimage.com/101x100.png/cc0000/ffffff", "rating": 46 },
{ "id": 695, "Name": "Pharmacal-International. Co., Ltd.", "image": "http://dummyimage.com/111x100.png/dddddd/000000", "rating": 32 },
{ "id": 696, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/157x100.png/5fa2dd/ffffff", "rating": 53 },
{ "id": 697, "Name": "Singhfam Corporation", "image": "http://dummyimage.com/211x100.png/ff4444/ffffff", "rating": 79 },
{ "id": 698, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/133x100.png/dddddd/000000", "rating": 77 },
{ "id": 699, "Name": "Topco Associates LLC", "image": "http://dummyimage.com/127x100.png/ff4444/ffffff", "rating": 95 },
{ "id": 700, "Name": "Lake Erie Medical DBA Quality Care Products LLC", "image": "http://dummyimage.com/200x100.png/dddddd/000000", "rating": 37 },
{ "id": 701, "Name": "Hospira, Inc.", "image": "http://dummyimage.com/154x100.png/dddddd/000000", "rating": 78 },
{ "id": 702, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/140x100.png/ff4444/ffffff", "rating": 46 },
{ "id": 703, "Name": "Johnson & Johnson Consumer Products Company, Division of Johnson & Johnson Consumer Companies, Inc.", "image": "http://dummyimage.com/232x100.png/dddddd/000000", "rating": 13 },
{ "id": 704, "Name": "STI Pharma LLC", "image": "http://dummyimage.com/244x100.png/cc0000/ffffff", "rating": 16 },
{ "id": 705, "Name": "SAFEWAY INC", "image": "http://dummyimage.com/115x100.png/cc0000/ffffff", "rating": 43 },
{ "id": 706, "Name": "Cardinal Health", "image": "http://dummyimage.com/149x100.png/cc0000/ffffff", "rating": 69 },
{ "id": 707, "Name": "DermWorx Incorporated", "image": "http://dummyimage.com/204x100.png/ff4444/ffffff", "rating": 81 },
{ "id": 708, "Name": "CVS Pharmacy", "image": "http://dummyimage.com/142x100.png/dddddd/000000", "rating": 26 },
{ "id": 709, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/126x100.png/dddddd/000000", "rating": 93 },
{ "id": 710, "Name": "Cardinal Health", "image": "http://dummyimage.com/194x100.png/5fa2dd/ffffff", "rating": 26 },
{ "id": 711, "Name": "Supervalu Inc", "image": "http://dummyimage.com/172x100.png/dddddd/000000", "rating": 33 },
{ "id": 712, "Name": "Sun & Skin Care Research, LLC", "image": "http://dummyimage.com/144x100.png/5fa2dd/ffffff", "rating": 89 },
{ "id": 713, "Name": "ENCHANTE ACCESSORIES INC.", "image": "http://dummyimage.com/179x100.png/cc0000/ffffff", "rating": 83 },
{ "id": 714, "Name": "Babor Cosmetics America, Corp.", "image": "http://dummyimage.com/237x100.png/cc0000/ffffff", "rating": 99 },
{ "id": 715, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/160x100.png/dddddd/000000", "rating": 39 },
{ "id": 716, "Name": "L. Perrigo Company", "image": "http://dummyimage.com/101x100.png/cc0000/ffffff", "rating": 66 },
{ "id": 717, "Name": "HOMEOLAB USA INC", "image": "http://dummyimage.com/187x100.png/dddddd/000000", "rating": 48 },
{ "id": 718, "Name": "Morton Grove Pharmaceuticals, Inc.", "image": "http://dummyimage.com/198x100.png/5fa2dd/ffffff", "rating": 87 },
{ "id": 719, "Name": "CorePharma, LLC", "image": "http://dummyimage.com/178x100.png/5fa2dd/ffffff", "rating": 96 },
{ "id": 720, "Name": "Roxane Laboratories, Inc.", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 56 },
{ "id": 721, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 57 },
{ "id": 722, "Name": "Major Pharmaceuticals", "image": "http://dummyimage.com/117x100.png/5fa2dd/ffffff", "rating": 14 },
{ "id": 723, "Name": "AAA Pharmaceutical, Inc.", "image": "http://dummyimage.com/180x100.png/dddddd/000000", "rating": 69 },
{ "id": 724, "Name": "Industrial Source", "image": "http://dummyimage.com/169x100.png/dddddd/000000", "rating": 4 },
{ "id": 725, "Name": "State of Florida DOH Central Pharmacy", "image": "http://dummyimage.com/179x100.png/cc0000/ffffff", "rating": 6 },
{ "id": 726, "Name": "Ventura International LTD.", "image": "http://dummyimage.com/123x100.png/5fa2dd/ffffff", "rating": 15 },
{ "id": 727, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/124x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 728, "Name": "Med Health Pharma, LLC", "image": "http://dummyimage.com/163x100.png/ff4444/ffffff", "rating": 28 },
{ "id": 729, "Name": "Perrigo New York Inc", "image": "http://dummyimage.com/169x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 730, "Name": "Topco Associates LLC", "image": "http://dummyimage.com/181x100.png/5fa2dd/ffffff", "rating": 21 },
{ "id": 731, "Name": "Nartex Laboratorios Homeopaticos, S.A. De C.V.", "image": "http://dummyimage.com/117x100.png/5fa2dd/ffffff", "rating": 10 },
{ "id": 732, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/241x100.png/5fa2dd/ffffff", "rating": 96 },
{ "id": 733, "Name": "Ventura International Ltd", "image": "http://dummyimage.com/150x100.png/dddddd/000000", "rating": 76 },
{ "id": 734, "Name": "Target Corporation", "image": "http://dummyimage.com/129x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 735, "Name": "CVS Pharmacy Inc", "image": "http://dummyimage.com/245x100.png/5fa2dd/ffffff", "rating": 1 },
{ "id": 736, "Name": "Scarguard Labs, LLC", "image": "http://dummyimage.com/220x100.png/ff4444/ffffff", "rating": 53 },
{ "id": 737, "Name": "Ventura Corporation LTD", "image": "http://dummyimage.com/221x100.png/5fa2dd/ffffff", "rating": 70 },
{ "id": 738, "Name": "Ventura Corporation Ltd.", "image": "http://dummyimage.com/135x100.png/dddddd/000000", "rating": 32 },
{ "id": 739, "Name": "Newton Laboratories, Inc.", "image": "http://dummyimage.com/225x100.png/5fa2dd/ffffff", "rating": 6 },
{ "id": 740, "Name": "Ventura Corporation, LTD", "image": "http://dummyimage.com/176x100.png/ff4444/ffffff", "rating": 95 },
{ "id": 741, "Name": "Target Corporation", "image": "http://dummyimage.com/137x100.png/cc0000/ffffff", "rating": 49 },
{ "id": 742, "Name": "Cardinal Health", "image": "http://dummyimage.com/159x100.png/5fa2dd/ffffff", "rating": 93 },
{ "id": 743, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/244x100.png/ff4444/ffffff", "rating": 92 },
{ "id": 744, "Name": "Western Family Foods Inc", "image": "http://dummyimage.com/111x100.png/cc0000/ffffff", "rating": 60 },
{ "id": 745, "Name": "ABC Compounding Co., Inc.", "image": "http://dummyimage.com/177x100.png/ff4444/ffffff", "rating": 14 },
{ "id": 746, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/118x100.png/dddddd/000000", "rating": 83 },
{ "id": 747, "Name": "Carilion Materials Management", "image": "http://dummyimage.com/158x100.png/cc0000/ffffff", "rating": 21 },
{ "id": 748, "Name": "MSD Consumer Care, Inc.", "image": "http://dummyimage.com/234x100.png/cc0000/ffffff", "rating": 24 },
{ "id": 749, "Name": "Rebel Distributors Corp.", "image": "http://dummyimage.com/158x100.png/cc0000/ffffff", "rating": 44 },
{ "id": 750, "Name": "Burkhart Dental Supply Inc", "image": "http://dummyimage.com/144x100.png/5fa2dd/ffffff", "rating": 69 },
{ "id": 751, "Name": "Apotex Corp.", "image": "http://dummyimage.com/102x100.png/5fa2dd/ffffff", "rating": 55 },
{ "id": 752, "Name": "Cantrell Drug Company", "image": "http://dummyimage.com/136x100.png/ff4444/ffffff", "rating": 48 },
{ "id": 753, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/139x100.png/ff4444/ffffff", "rating": 28 },
{ "id": 754, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/123x100.png/dddddd/000000", "rating": 37 },
{ "id": 755, "Name": "Publix Super Markets, Inc", "image": "http://dummyimage.com/207x100.png/cc0000/ffffff", "rating": 2 },
{ "id": 756, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/207x100.png/ff4444/ffffff", "rating": 71 },
{ "id": 757, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/166x100.png/dddddd/000000", "rating": 35 },
{ "id": 758, "Name": "Upsher-Smith Laboratories, Inc.", "image": "http://dummyimage.com/127x100.png/ff4444/ffffff", "rating": 61 },
{ "id": 759, "Name": "Qualitest Pharmaceuticals", "image": "http://dummyimage.com/132x100.png/cc0000/ffffff", "rating": 73 },
{ "id": 760, "Name": "Geiss, Destin & Dunn, Inc.", "image": "http://dummyimage.com/229x100.png/5fa2dd/ffffff", "rating": 16 },
{ "id": 761, "Name": "Lupin Pharmaceuticals, Inc.", "image": "http://dummyimage.com/123x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 762, "Name": "HIMPRIT PHARMACHEM PVT LTD", "image": "http://dummyimage.com/161x100.png/cc0000/ffffff", "rating": 31 },
{ "id": 763, "Name": "SHISEIDO AMERICA INC.", "image": "http://dummyimage.com/204x100.png/dddddd/000000", "rating": 65 },
{ "id": 764, "Name": "Sandoz Inc", "image": "http://dummyimage.com/137x100.png/ff4444/ffffff", "rating": 75 },
{ "id": 765, "Name": "Walgreen Company", "image": "http://dummyimage.com/109x100.png/5fa2dd/ffffff", "rating": 40 },
{ "id": 766, "Name": "Carilion Materials Management", "image": "http://dummyimage.com/205x100.png/dddddd/000000", "rating": 40 },
{ "id": 767, "Name": "Bausch & Lomb Incorporated", "image": "http://dummyimage.com/156x100.png/dddddd/000000", "rating": 55 },
{ "id": 768, "Name": "SUPERVALU INC.", "image": "http://dummyimage.com/165x100.png/ff4444/ffffff", "rating": 73 },
{ "id": 769, "Name": "Aurobindo Pharma Limited", "image": "http://dummyimage.com/176x100.png/cc0000/ffffff", "rating": 36 },
{ "id": 770, "Name": "E-Z-EM Canada Inc", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 47 },
{ "id": 771, "Name": "Sandoz Inc", "image": "http://dummyimage.com/142x100.png/ff4444/ffffff", "rating": 87 },
{ "id": 772, "Name": "Bayer HealthCare LLC, Consumer Care", "image": "http://dummyimage.com/142x100.png/cc0000/ffffff", "rating": 52 },
{ "id": 773, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/199x100.png/dddddd/000000", "rating": 5 },
{ "id": 774, "Name": "Sandoz Inc", "image": "http://dummyimage.com/162x100.png/dddddd/000000", "rating": 4 },
{ "id": 775, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/215x100.png/5fa2dd/ffffff", "rating": 51 },
{ "id": 776, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/192x100.png/ff4444/ffffff", "rating": 55 },
{ "id": 777, "Name": "Accra-Pac, Inc.", "image": "http://dummyimage.com/182x100.png/dddddd/000000", "rating": 56 },
{ "id": 778, "Name": "Donovan Industries, Inc", "image": "http://dummyimage.com/203x100.png/cc0000/ffffff", "rating": 54 },
{ "id": 779, "Name": "Lake Erie Medical & Surgical Supply DBA Quality Care Products LLC", "image": "http://dummyimage.com/229x100.png/cc0000/ffffff", "rating": 21 },
{ "id": 780, "Name": "Meijer Distribution Inc", "image": "http://dummyimage.com/167x100.png/cc0000/ffffff", "rating": 38 },
{ "id": 781, "Name": "Cardinal Health", "image": "http://dummyimage.com/153x100.png/dddddd/000000", "rating": 94 },
{ "id": 782, "Name": "CLINIQUE LABORATORIES INC.", "image": "http://dummyimage.com/185x100.png/ff4444/ffffff", "rating": 21 },
{ "id": 783, "Name": "Elizabeth Arden, Inc", "image": "http://dummyimage.com/123x100.png/cc0000/ffffff", "rating": 89 },
{ "id": 784, "Name": "International Nature Nutraceuticals", "image": "http://dummyimage.com/145x100.png/ff4444/ffffff", "rating": 74 },
{ "id": 785, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/228x100.png/ff4444/ffffff", "rating": 69 },
{ "id": 786, "Name": "Parfums Christian Dior", "image": "http://dummyimage.com/156x100.png/5fa2dd/ffffff", "rating": 71 },
{ "id": 787, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/183x100.png/dddddd/000000", "rating": 71 },
{ "id": 788, "Name": "Camber Pharmaceuticals, Inc.", "image": "http://dummyimage.com/229x100.png/cc0000/ffffff", "rating": 13 },
{ "id": 789, "Name": "Stat Rx USA", "image": "http://dummyimage.com/188x100.png/ff4444/ffffff", "rating": 22 },
{ "id": 790, "Name": "Lake Erie Medical & Surgical Supply DBA Quality Care Products LLC", "image": "http://dummyimage.com/165x100.png/ff4444/ffffff", "rating": 30 },
{ "id": 791, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/141x100.png/5fa2dd/ffffff", "rating": 78 },
{ "id": 792, "Name": "WOCKHARDT LIMITED", "image": "http://dummyimage.com/228x100.png/5fa2dd/ffffff", "rating": 59 },
{ "id": 793, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/142x100.png/ff4444/ffffff", "rating": 78 },
{ "id": 794, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/211x100.png/ff4444/ffffff", "rating": 49 },
{ "id": 795, "Name": "NARTEX LABORATORIOS HOMEOPATICOS SA DE CV", "image": "http://dummyimage.com/144x100.png/cc0000/ffffff", "rating": 57 },
{ "id": 796, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/249x100.png/5fa2dd/ffffff", "rating": 99 },
{ "id": 797, "Name": "G&W Laboratories, Inc.", "image": "http://dummyimage.com/237x100.png/ff4444/ffffff", "rating": 8 },
{ "id": 798, "Name": "McKesson Packaging Services a business unit of McKesson Corporation", "image": "http://dummyimage.com/229x100.png/5fa2dd/ffffff", "rating": 30 },
{ "id": 799, "Name": "Hyland's", "image": "http://dummyimage.com/248x100.png/5fa2dd/ffffff", "rating": 94 },
{ "id": 800, "Name": "Uriel Pharmacy Inc", "image": "http://dummyimage.com/206x100.png/dddddd/000000", "rating": 50 },
{ "id": 801, "Name": "Sunovion Pharmaceuticals Inc.", "image": "http://dummyimage.com/132x100.png/ff4444/ffffff", "rating": 4 },
{ "id": 802, "Name": "American Sales Company", "image": "http://dummyimage.com/170x100.png/5fa2dd/ffffff", "rating": 26 },
{ "id": 803, "Name": "E.R. Squibb & Sons, L.L.C.", "image": "http://dummyimage.com/156x100.png/dddddd/000000", "rating": 2 },
{ "id": 804, "Name": "HOMEOLAB USA INC.", "image": "http://dummyimage.com/238x100.png/cc0000/ffffff", "rating": 23 },
{ "id": 805, "Name": "Ross Medical Supply Co. Inc.", "image": "http://dummyimage.com/228x100.png/ff4444/ffffff", "rating": 5 },
{ "id": 806, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/162x100.png/ff4444/ffffff", "rating": 44 },
{ "id": 807, "Name": "Bristol-Myers Squibb Pharma Company", "image": "http://dummyimage.com/124x100.png/5fa2dd/ffffff", "rating": 57 },
{ "id": 808, "Name": "Home Sweet Homeopathics", "image": "http://dummyimage.com/223x100.png/cc0000/ffffff", "rating": 35 },
{ "id": 809, "Name": "McKesson Contract Packaging", "image": "http://dummyimage.com/141x100.png/5fa2dd/ffffff", "rating": 44 },
{ "id": 810, "Name": "STAT Rx USA LLC", "image": "http://dummyimage.com/133x100.png/ff4444/ffffff", "rating": 94 },
{ "id": 811, "Name": "Remedy Makers", "image": "http://dummyimage.com/111x100.png/cc0000/ffffff", "rating": 3 },
{ "id": 812, "Name": "Mallinckrodt, Inc.", "image": "http://dummyimage.com/143x100.png/cc0000/ffffff", "rating": 92 },
{ "id": 813, "Name": "Ranbaxy Pharmaceuticals Inc.", "image": "http://dummyimage.com/102x100.png/5fa2dd/ffffff", "rating": 46 },
{ "id": 814, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/233x100.png/dddddd/000000", "rating": 33 },
{ "id": 815, "Name": "Cardinal Health", "image": "http://dummyimage.com/144x100.png/cc0000/ffffff", "rating": 67 },
{ "id": 816, "Name": "Watson Laboratories, Inc.", "image": "http://dummyimage.com/213x100.png/dddddd/000000", "rating": 70 },
{ "id": 817, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/171x100.png/ff4444/ffffff", "rating": 29 },
{ "id": 818, "Name": "Sion Biotext Medical Ltd", "image": "http://dummyimage.com/120x100.png/dddddd/000000", "rating": 100 },
{ "id": 819, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/126x100.png/dddddd/000000", "rating": 96 },
{ "id": 820, "Name": "Sun Pharma Global FZE", "image": "http://dummyimage.com/246x100.png/dddddd/000000", "rating": 13 },
{ "id": 821, "Name": "HUSH Anesthetic", "image": "http://dummyimage.com/182x100.png/cc0000/ffffff", "rating": 54 },
{ "id": 822, "Name": "Aidarex Pharmaceuticals LLC", "image": "http://dummyimage.com/216x100.png/cc0000/ffffff", "rating": 14 },
{ "id": 823, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/196x100.png/dddddd/000000", "rating": 71 },
{ "id": 824, "Name": "Jubilant HollisterStier LLC", "image": "http://dummyimage.com/143x100.png/cc0000/ffffff", "rating": 95 },
{ "id": 825, "Name": "Rebel Distributors Corp", "image": "http://dummyimage.com/133x100.png/dddddd/000000", "rating": 45 },
{ "id": 826, "Name": "J. A. Cosmetics U.S. INC", "image": "http://dummyimage.com/205x100.png/ff4444/ffffff", "rating": 100 },
{ "id": 827, "Name": "Aidarex Pharmaceuticals LLC", "image": "http://dummyimage.com/201x100.png/ff4444/ffffff", "rating": 37 },
{ "id": 828, "Name": "L.N.K. International, Inc.", "image": "http://dummyimage.com/105x100.png/dddddd/000000", "rating": 17 },
{ "id": 829, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/206x100.png/5fa2dd/ffffff", "rating": 34 },
{ "id": 830, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/172x100.png/dddddd/000000", "rating": 70 },
{ "id": 831, "Name": "Star Pharmaceuticals, LLC", "image": "http://dummyimage.com/209x100.png/5fa2dd/ffffff", "rating": 24 },
{ "id": 832, "Name": "Dr. Forhair", "image": "http://dummyimage.com/178x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 833, "Name": "Ventura Corporation (San Juan, P.R)", "image": "http://dummyimage.com/178x100.png/5fa2dd/ffffff", "rating": 48 },
{ "id": 834, "Name": "Hi-Tech Pharmacal Co., Inc.", "image": "http://dummyimage.com/143x100.png/ff4444/ffffff", "rating": 78 },
{ "id": 835, "Name": "BioActive Nutritional, Inc.", "image": "http://dummyimage.com/222x100.png/5fa2dd/ffffff", "rating": 86 },
{ "id": 836, "Name": "Stiefel Laboratories Inc", "image": "http://dummyimage.com/109x100.png/5fa2dd/ffffff", "rating": 97 },
{ "id": 837, "Name": "Walgreen Company", "image": "http://dummyimage.com/224x100.png/5fa2dd/ffffff", "rating": 49 },
{ "id": 838, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/194x100.png/dddddd/000000", "rating": 88 },
{ "id": 839, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/102x100.png/ff4444/ffffff", "rating": 58 },
{ "id": 840, "Name": "Antigen Laboratories, Inc.", "image": "http://dummyimage.com/125x100.png/dddddd/000000", "rating": 33 },
{ "id": 841, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/220x100.png/dddddd/000000", "rating": 37 },
{ "id": 842, "Name": "Rising Pharmaceuticals, Inc.", "image": "http://dummyimage.com/208x100.png/5fa2dd/ffffff", "rating": 66 },
{ "id": 843, "Name": "The Mentholatum Company", "image": "http://dummyimage.com/171x100.png/cc0000/ffffff", "rating": 51 },
{ "id": 844, "Name": "CAO Group, Inc.", "image": "http://dummyimage.com/209x100.png/cc0000/ffffff", "rating": 46 },
{ "id": 845, "Name": "Cardinal Health", "image": "http://dummyimage.com/165x100.png/dddddd/000000", "rating": 67 },
{ "id": 846, "Name": "L Perrigo Company", "image": "http://dummyimage.com/167x100.png/cc0000/ffffff", "rating": 32 },
{ "id": 847, "Name": "Heritage Pharmaceuticals Inc.", "image": "http://dummyimage.com/114x100.png/5fa2dd/ffffff", "rating": 95 },
{ "id": 848, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/205x100.png/dddddd/000000", "rating": 53 },
{ "id": 849, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 14 },
{ "id": 850, "Name": "Pfizer Laboratories Div Pfizer Inc", "image": "http://dummyimage.com/190x100.png/ff4444/ffffff", "rating": 57 },
{ "id": 851, "Name": "Teva Pharmaceuticals USA Inc", "image": "http://dummyimage.com/124x100.png/ff4444/ffffff", "rating": 37 },
{ "id": 852, "Name": "Par Pharmaceutical Inc.", "image": "http://dummyimage.com/183x100.png/5fa2dd/ffffff", "rating": 68 },
{ "id": 853, "Name": "BCM Ltd", "image": "http://dummyimage.com/140x100.png/ff4444/ffffff", "rating": 76 },
{ "id": 854, "Name": "Camber Pharmaceuticals", "image": "http://dummyimage.com/137x100.png/dddddd/000000", "rating": 65 },
{ "id": 855, "Name": "Mycone Dental Supply Co., Inc DBA Keystone Industries and Deepak Products Inc.", "image": "http://dummyimage.com/215x100.png/dddddd/000000", "rating": 83 },
{ "id": 856, "Name": "General Injectables & Vaccines, Inc", "image": "http://dummyimage.com/162x100.png/dddddd/000000", "rating": 76 },
{ "id": 857, "Name": "ETUDE HOUSE", "image": "http://dummyimage.com/117x100.png/cc0000/ffffff", "rating": 63 },
{ "id": 858, "Name": "Tranzonic Companies", "image": "http://dummyimage.com/171x100.png/dddddd/000000", "rating": 33 },
{ "id": 859, "Name": "Allison Medical Inc.", "image": "http://dummyimage.com/217x100.png/dddddd/000000", "rating": 25 },
{ "id": 860, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/142x100.png/dddddd/000000", "rating": 54 },
{ "id": 861, "Name": "Perrigo New York Inc", "image": "http://dummyimage.com/192x100.png/5fa2dd/ffffff", "rating": 20 },
{ "id": 862, "Name": "Family Dollar", "image": "http://dummyimage.com/225x100.png/dddddd/000000", "rating": 52 },
{ "id": 863, "Name": "Blistex Inc.", "image": "http://dummyimage.com/152x100.png/5fa2dd/ffffff", "rating": 4 },
{ "id": 864, "Name": "Uriel Pharmacy Inc.", "image": "http://dummyimage.com/194x100.png/cc0000/ffffff", "rating": 22 },
{ "id": 865, "Name": "Swiss-American Products", "image": "http://dummyimage.com/107x100.png/cc0000/ffffff", "rating": 71 },
{ "id": 866, "Name": "Solco Healthcare US LLC", "image": "http://dummyimage.com/118x100.png/cc0000/ffffff", "rating": 60 },
{ "id": 867, "Name": "Mylan Pharmaceuticals Inc.", "image": "http://dummyimage.com/206x100.png/cc0000/ffffff", "rating": 100 },
{ "id": 868, "Name": "Lornamead", "image": "http://dummyimage.com/224x100.png/5fa2dd/ffffff", "rating": 93 },
{ "id": 869, "Name": "Fresenius Kabi USA, LLC", "image": "http://dummyimage.com/140x100.png/5fa2dd/ffffff", "rating": 13 },
{ "id": 870, "Name": "Sandoz Inc", "image": "http://dummyimage.com/199x100.png/cc0000/ffffff", "rating": 61 },
{ "id": 871, "Name": "King Bio Inc", "image": "http://dummyimage.com/106x100.png/5fa2dd/ffffff", "rating": 54 },
{ "id": 872, "Name": "American Sales Company", "image": "http://dummyimage.com/122x100.png/ff4444/ffffff", "rating": 23 },
{ "id": 873, "Name": "KAISER FOUNDATION HOSPITALS", "image": "http://dummyimage.com/226x100.png/dddddd/000000", "rating": 98 },
{ "id": 874, "Name": "America Medic", "image": "http://dummyimage.com/248x100.png/ff4444/ffffff", "rating": 50 },
{ "id": 875, "Name": "Clinical Solutions Wholesale", "image": "http://dummyimage.com/174x100.png/dddddd/000000", "rating": 96 },
{ "id": 876, "Name": "Five Below", "image": "http://dummyimage.com/207x100.png/cc0000/ffffff", "rating": 70 },
{ "id": 877, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/208x100.png/cc0000/ffffff", "rating": 85 },
{ "id": 878, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/117x100.png/dddddd/000000", "rating": 84 },
{ "id": 879, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/137x100.png/cc0000/ffffff", "rating": 54 },
{ "id": 880, "Name": "Hu-Friedy Mfg. Co., Inc.", "image": "http://dummyimage.com/228x100.png/dddddd/000000", "rating": 62 },
{ "id": 881, "Name": "Forest Laboratories, Inc.", "image": "http://dummyimage.com/191x100.png/dddddd/000000", "rating": 35 },
{ "id": 882, "Name": "Amneal Pharmaceuticals of New York, LLC", "image": "http://dummyimage.com/225x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 883, "Name": "Cardinal Health", "image": "http://dummyimage.com/218x100.png/5fa2dd/ffffff", "rating": 46 },
{ "id": 884, "Name": "DZA Brands LLC", "image": "http://dummyimage.com/220x100.png/5fa2dd/ffffff", "rating": 46 },
{ "id": 885, "Name": "Rocco's Old School", "image": "http://dummyimage.com/179x100.png/ff4444/ffffff", "rating": 84 },
{ "id": 886, "Name": "CorePharma, LLC", "image": "http://dummyimage.com/145x100.png/dddddd/000000", "rating": 48 },
{ "id": 887, "Name": "Perrigo New York Inc", "image": "http://dummyimage.com/177x100.png/dddddd/000000", "rating": 75 },
{ "id": 888, "Name": "Proficient Rx", "image": "http://dummyimage.com/159x100.png/dddddd/000000", "rating": 38 },
{ "id": 889, "Name": "RedPharm Drug Inc.", "image": "http://dummyimage.com/106x100.png/ff4444/ffffff", "rating": 53 },
{ "id": 890, "Name": "Teva Select Brands", "image": "http://dummyimage.com/202x100.png/cc0000/ffffff", "rating": 15 },
{ "id": 891, "Name": "Cardinal Health", "image": "http://dummyimage.com/244x100.png/dddddd/000000", "rating": 17 },
{ "id": 892, "Name": "Demoulas Super Markets Inc", "image": "http://dummyimage.com/185x100.png/ff4444/ffffff", "rating": 85 },
{ "id": 893, "Name": "McKesson Packaging Services a business unit of McKesson Corporation", "image": "http://dummyimage.com/103x100.png/ff4444/ffffff", "rating": 98 },
{ "id": 894, "Name": "Apotheca Company", "image": "http://dummyimage.com/178x100.png/dddddd/000000", "rating": 87 },
{ "id": 895, "Name": "Golden State Medical Supply", "image": "http://dummyimage.com/249x100.png/5fa2dd/ffffff", "rating": 62 },
{ "id": 896, "Name": "Kmart Corporation", "image": "http://dummyimage.com/169x100.png/cc0000/ffffff", "rating": 59 },
{ "id": 897, "Name": "Amerifoods Trading Company", "image": "http://dummyimage.com/172x100.png/dddddd/000000", "rating": 91 },
{ "id": 898, "Name": "Talon Therapeutics, Inc", "image": "http://dummyimage.com/186x100.png/ff4444/ffffff", "rating": 87 },
{ "id": 899, "Name": "Watson Pharma, Inc.", "image": "http://dummyimage.com/178x100.png/cc0000/ffffff", "rating": 87 },
{ "id": 900, "Name": "MSD Consumer Care, Inc.", "image": "http://dummyimage.com/128x100.png/dddddd/000000", "rating": 6 },
{ "id": 901, "Name": "Premium Formulations LLC", "image": "http://dummyimage.com/126x100.png/cc0000/ffffff", "rating": 94 },
{ "id": 902, "Name": "American Welding & Gas", "image": "http://dummyimage.com/206x100.png/dddddd/000000", "rating": 88 },
{ "id": 903, "Name": "HANLIM PHARM. CO., LTD.", "image": "http://dummyimage.com/160x100.png/ff4444/ffffff", "rating": 68 },
{ "id": 904, "Name": "Newton Laboratories, Inc.", "image": "http://dummyimage.com/178x100.png/cc0000/ffffff", "rating": 3 },
{ "id": 905, "Name": "Triad Group", "image": "http://dummyimage.com/149x100.png/5fa2dd/ffffff", "rating": 63 },
{ "id": 906, "Name": "ProBLEN", "image": "http://dummyimage.com/128x100.png/ff4444/ffffff", "rating": 45 },
{ "id": 907, "Name": "Performance Health, Inc.", "image": "http://dummyimage.com/204x100.png/cc0000/ffffff", "rating": 42 },
{ "id": 908, "Name": "Par Pharmaceutical Companies, Inc.", "image": "http://dummyimage.com/201x100.png/dddddd/000000", "rating": 9 },
{ "id": 909, "Name": "Procter & Gamble Manufacturing Company", "image": "http://dummyimage.com/155x100.png/dddddd/000000", "rating": 86 },
{ "id": 910, "Name": "Apotheca Company", "image": "http://dummyimage.com/127x100.png/5fa2dd/ffffff", "rating": 47 },
{ "id": 911, "Name": "Contract Pharmacy Services-PA", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 88 },
{ "id": 912, "Name": "Bryant Ranch Prepack", "image": "http://dummyimage.com/155x100.png/ff4444/ffffff", "rating": 38 },
{ "id": 913, "Name": "A Refreshing Discovery, Inc.", "image": "http://dummyimage.com/180x100.png/dddddd/000000", "rating": 83 },
{ "id": 914, "Name": "Liddell Laboratories, Inc.", "image": "http://dummyimage.com/228x100.png/5fa2dd/ffffff", "rating": 89 },
{ "id": 915, "Name": "Taro Pharmaceuticals U.S.A., Inc.", "image": "http://dummyimage.com/146x100.png/dddddd/000000", "rating": 20 },
{ "id": 916, "Name": "Deseret Biologicals, Inc.", "image": "http://dummyimage.com/215x100.png/dddddd/000000", "rating": 8 },
{ "id": 917, "Name": "Babor Cosmetics America, Corp", "image": "http://dummyimage.com/190x100.png/ff4444/ffffff", "rating": 75 },
{ "id": 918, "Name": "Taro Pharmaceuticals U.S.A., Inc.", "image": "http://dummyimage.com/119x100.png/ff4444/ffffff", "rating": 77 },
{ "id": 919, "Name": "Lake Erie Medical DBA Quality Care Products LLC", "image": "http://dummyimage.com/193x100.png/ff4444/ffffff", "rating": 47 },
{ "id": 920, "Name": "Baxter Healthcare Corporation", "image": "http://dummyimage.com/165x100.png/ff4444/ffffff", "rating": 39 },
{ "id": 921, "Name": "Pfizer Laboratories Div Pfizer Inc", "image": "http://dummyimage.com/116x100.png/dddddd/000000", "rating": 94 },
{ "id": 922, "Name": "Homeostasis Laboratories, Inc.", "image": "http://dummyimage.com/117x100.png/ff4444/ffffff", "rating": 6 },
{ "id": 923, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/183x100.png/dddddd/000000", "rating": 76 },
{ "id": 924, "Name": "Macleods Pharmaceuticals Limited", "image": "http://dummyimage.com/131x100.png/dddddd/000000", "rating": 1 },
{ "id": 925, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/130x100.png/5fa2dd/ffffff", "rating": 7 },
{ "id": 926, "Name": "Pfizer Laboratories Div Pfizer Inc", "image": "http://dummyimage.com/215x100.png/ff4444/ffffff", "rating": 89 },
{ "id": 927, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/244x100.png/cc0000/ffffff", "rating": 20 },
{ "id": 928, "Name": "Nelco Laboratories, Inc.", "image": "http://dummyimage.com/175x100.png/5fa2dd/ffffff", "rating": 60 },
{ "id": 929, "Name": "Natural Health Supply", "image": "http://dummyimage.com/196x100.png/ff4444/ffffff", "rating": 95 },
{ "id": 930, "Name": "Allermed Laboratories, Inc.", "image": "http://dummyimage.com/148x100.png/5fa2dd/ffffff", "rating": 70 },
{ "id": 931, "Name": "Allergy Laboratories, Inc.", "image": "http://dummyimage.com/143x100.png/cc0000/ffffff", "rating": 13 },
{ "id": 932, "Name": "Prasco Laboratories", "image": "http://dummyimage.com/217x100.png/dddddd/000000", "rating": 87 },
{ "id": 933, "Name": "Blistex inc", "image": "http://dummyimage.com/217x100.png/cc0000/ffffff", "rating": 9 },
{ "id": 934, "Name": "Nutri-Dyn Products Ltd. dba Professional Health Products", "image": "http://dummyimage.com/191x100.png/cc0000/ffffff", "rating": 100 },
{ "id": 935, "Name": "AMI Cosmetic Co.,Ltd.", "image": "http://dummyimage.com/180x100.png/ff4444/ffffff", "rating": 97 },
{ "id": 936, "Name": "BrandStorm HBC", "image": "http://dummyimage.com/178x100.png/5fa2dd/ffffff", "rating": 40 },
{ "id": 937, "Name": "Preferred Pharmaceuticals, Inc", "image": "http://dummyimage.com/212x100.png/5fa2dd/ffffff", "rating": 12 },
{ "id": 938, "Name": "NCS HealthCare of KY, Inc dba Vangard Labs", "image": "http://dummyimage.com/137x100.png/dddddd/000000", "rating": 68 },
{ "id": 939, "Name": "Kosan Kozmetik Sanayi ve Ticaret A.S.", "image": "http://dummyimage.com/217x100.png/dddddd/000000", "rating": 42 },
{ "id": 940, "Name": "A-S Medication Solutions LLC", "image": "http://dummyimage.com/186x100.png/ff4444/ffffff", "rating": 71 },
{ "id": 941, "Name": "Alembic Pharmaceuticals Limited", "image": "http://dummyimage.com/125x100.png/dddddd/000000", "rating": 82 },
{ "id": 942, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/121x100.png/ff4444/ffffff", "rating": 66 },
{ "id": 943, "Name": "Blenheim Pharmacal, Inc.", "image": "http://dummyimage.com/236x100.png/5fa2dd/ffffff", "rating": 68 },
{ "id": 944, "Name": "Life Line Home Care Services, Inc.", "image": "http://dummyimage.com/115x100.png/dddddd/000000", "rating": 28 },
{ "id": 945, "Name": "ZymoGenetics, Inc.", "image": "http://dummyimage.com/124x100.png/cc0000/ffffff", "rating": 82 },
{ "id": 946, "Name": "American Health Packaging", "image": "http://dummyimage.com/140x100.png/5fa2dd/ffffff", "rating": 87 },
{ "id": 947, "Name": "Aphena Pharma Solutions - New Jersey, LLC", "image": "http://dummyimage.com/122x100.png/ff4444/ffffff", "rating": 52 },
{ "id": 948, "Name": "Amerisource Bergen", "image": "http://dummyimage.com/144x100.png/dddddd/000000", "rating": 22 },
{ "id": 949, "Name": "Gavis Pharmaceuticals, LLC.", "image": "http://dummyimage.com/150x100.png/5fa2dd/ffffff", "rating": 5 },
{ "id": 950, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/153x100.png/ff4444/ffffff", "rating": 2 },
{ "id": 951, "Name": "American Health Packaging", "image": "http://dummyimage.com/168x100.png/ff4444/ffffff", "rating": 54 },
{ "id": 952, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/174x100.png/ff4444/ffffff", "rating": 51 },
{ "id": 953, "Name": "Cherry Hill Sales Co", "image": "http://dummyimage.com/189x100.png/5fa2dd/ffffff", "rating": 93 },
{ "id": 954, "Name": "Dynamic Pharmaceuticals Inc.", "image": "http://dummyimage.com/186x100.png/ff4444/ffffff", "rating": 89 },
{ "id": 955, "Name": "ALK-Abello, Inc.", "image": "http://dummyimage.com/187x100.png/ff4444/ffffff", "rating": 8 },
{ "id": 956, "Name": "Hi-Tech Pharmacal Co., Inc.", "image": "http://dummyimage.com/193x100.png/cc0000/ffffff", "rating": 33 },
{ "id": 957, "Name": "State of Florida DOH Central Pharmacy", "image": "http://dummyimage.com/202x100.png/ff4444/ffffff", "rating": 48 },
{ "id": 958, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/117x100.png/ff4444/ffffff", "rating": 27 },
{ "id": 959, "Name": "Medox Healthcare", "image": "http://dummyimage.com/198x100.png/5fa2dd/ffffff", "rating": 72 },
{ "id": 960, "Name": "Salado Sales, Inc.", "image": "http://dummyimage.com/190x100.png/ff4444/ffffff", "rating": 77 },
{ "id": 961, "Name": "Mylan Institutional Inc.", "image": "http://dummyimage.com/187x100.png/5fa2dd/ffffff", "rating": 51 },
{ "id": 962, "Name": "Cardinal Health", "image": "http://dummyimage.com/118x100.png/cc0000/ffffff", "rating": 80 },
{ "id": 963, "Name": "Mangiacotti Floral LLC", "image": "http://dummyimage.com/185x100.png/cc0000/ffffff", "rating": 78 },
{ "id": 964, "Name": "Lake Erie Medical DBA Quality Care Products LLC", "image": "http://dummyimage.com/228x100.png/ff4444/ffffff", "rating": 81 },
{ "id": 965, "Name": "Jafra Cosmetics International Inc", "image": "http://dummyimage.com/165x100.png/5fa2dd/ffffff", "rating": 20 },
{ "id": 966, "Name": "Forces of Nature", "image": "http://dummyimage.com/250x100.png/cc0000/ffffff", "rating": 4 },
{ "id": 967, "Name": "Zoe Processing", "image": "http://dummyimage.com/179x100.png/dddddd/000000", "rating": 45 },
{ "id": 968, "Name": "Dispensing Solutions Inc.", "image": "http://dummyimage.com/131x100.png/ff4444/ffffff", "rating": 70 },
{ "id": 969, "Name": "HyVee Inc", "image": "http://dummyimage.com/109x100.png/dddddd/000000", "rating": 70 },
{ "id": 970, "Name": "White Manufacturing Inc. DBA Micro-West", "image": "http://dummyimage.com/101x100.png/5fa2dd/ffffff", "rating": 2 },
{ "id": 971, "Name": "Ivey Industries", "image": "http://dummyimage.com/236x100.png/ff4444/ffffff", "rating": 21 },
{ "id": 972, "Name": "Ellen H Frankle MD Inc", "image": "http://dummyimage.com/142x100.png/ff4444/ffffff", "rating": 19 },
{ "id": 973, "Name": "Wal-Mart Stores Inc", "image": "http://dummyimage.com/177x100.png/ff4444/ffffff", "rating": 59 },
{ "id": 974, "Name": "E. FOUGERA & CO. A division of Fougera Pharmaceuticals Inc.", "image": "http://dummyimage.com/183x100.png/dddddd/000000", "rating": 83 },
{ "id": 975, "Name": "Major Pharmaceuticals", "image": "http://dummyimage.com/167x100.png/5fa2dd/ffffff", "rating": 59 },
{ "id": 976, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/243x100.png/5fa2dd/ffffff", "rating": 20 },
{ "id": 977, "Name": "Amneal Pharmaceuticals of New York, LLC", "image": "http://dummyimage.com/166x100.png/ff4444/ffffff", "rating": 72 },
{ "id": 978, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/115x100.png/dddddd/000000", "rating": 16 },
{ "id": 979, "Name": "E. Fougera & CO., A division of Fougera Pharmaceuticals Inc.", "image": "http://dummyimage.com/244x100.png/dddddd/000000", "rating": 31 },
{ "id": 980, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/201x100.png/cc0000/ffffff", "rating": 1 },
{ "id": 981, "Name": "Athlete's Needs Inc", "image": "http://dummyimage.com/211x100.png/dddddd/000000", "rating": 24 },
{ "id": 982, "Name": "DIRECT RX", "image": "http://dummyimage.com/161x100.png/ff4444/ffffff", "rating": 66 },
{ "id": 983, "Name": "The Mentholatum Company", "image": "http://dummyimage.com/160x100.png/ff4444/ffffff", "rating": 51 },
{ "id": 984, "Name": "SAVE-A-LOT FOOD STORES, LTD.", "image": "http://dummyimage.com/152x100.png/dddddd/000000", "rating": 63 },
{ "id": 985, "Name": "Be Better Networks", "image": "http://dummyimage.com/217x100.png/ff4444/ffffff", "rating": 57 },
{ "id": 986, "Name": "STAT RX USA LLC", "image": "http://dummyimage.com/106x100.png/ff4444/ffffff", "rating": 56 },
{ "id": 987, "Name": "Allermed Laboratories, Inc.", "image": "http://dummyimage.com/168x100.png/dddddd/000000", "rating": 79 },
{ "id": 988, "Name": "Palmer Fixture Company", "image": "http://dummyimage.com/231x100.png/ff4444/ffffff", "rating": 78 },
{ "id": 989, "Name": "Seroyal USA", "image": "http://dummyimage.com/211x100.png/ff4444/ffffff", "rating": 7 },
{ "id": 990, "Name": "Neutrogena Corporation", "image": "http://dummyimage.com/240x100.png/ff4444/ffffff", "rating": 26 },
{ "id": 991, "Name": "Watson Laboratories, Inc.", "image": "http://dummyimage.com/221x100.png/5fa2dd/ffffff", "rating": 11 },
{ "id": 992, "Name": "Enchante Accessories, Inc", "image": "http://dummyimage.com/133x100.png/dddddd/000000", "rating": 66 },
{ "id": 993, "Name": "Kroger Company", "image": "http://dummyimage.com/225x100.png/dddddd/000000", "rating": 73 },
{ "id": 994, "Name": "AR Medicom Inc", "image": "http://dummyimage.com/211x100.png/5fa2dd/ffffff", "rating": 68 },
{ "id": 995, "Name": "Ventura Corporation LTD", "image": "http://dummyimage.com/197x100.png/dddddd/000000", "rating": 48 },
{ "id": 996, "Name": "Physicians Total Care, Inc.", "image": "http://dummyimage.com/151x100.png/dddddd/000000", "rating": 11 },
{ "id": 997, "Name": "PD-Rx Pharmaceuticals, Inc.", "image": "http://dummyimage.com/172x100.png/cc0000/ffffff", "rating": 42 },
{ "id": 998, "Name": "Inland Supply Co., Inc.", "image": "http://dummyimage.com/142x100.png/cc0000/ffffff", "rating": 36 },
{ "id": 999, "Name": "REMEDYREPACK INC.", "image": "http://dummyimage.com/108x100.png/dddddd/000000", "rating": 12 },
{ "id": 1000, "Name": "SHISEIDO AMERICA INC.", "image": "http://dummyimage.com/207x100.png/ff4444/ffffff", "rating": 7 }]